import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import "../styles/businessBackofficeStyles.scss";
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
} from "reactstrap";
import {
  addDocumentToRelatedDocumentsGroup,
  archivePQRSCase,
  getDocumentAlerts,
  getDocumentById,
  getDocumentsAndEmailsByIds,
  getDocumentsByIds,
  getExtractionDropdownOptions,
  getExtractionFields,
  getRelatedDocumentGroup,
  respondPqrs,
  sendDocumentsEmail,
  sendTranslatableEmail,
  updateExtractionField,
  cloneRadicado,
} from "../../ApiCalls/mantisBusiness";
import { stringify } from "../../utils/dataExtractionUtils";
import { shortenText } from "../../utils/formatText";
import pdfIcon from "../../Assets/img/icons/pdf.png";
import imageIcon from "../../Assets/img/icons/image.png";
import excelIcon from "../../Assets/img/icons/excel.png";
import gmailIcon from "../../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";
import { checkMantisBusinessUserPermission } from "../../utils/businessUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function DocumentAnalysisModule(props) {
  //Possible types: alerts, documentDetail, relatedDocuments, brinksMain, brinksOfficeDocuments, tangeloRequirements, tangeloMain, tangeloCaseDocuments, tangeloCasePetitions, tangeloStatus

  const {
    title,
    type,
    mainId,
    selectedRegister,
    selectedRegisters,
    brinksOffice,
    brinksShownFile,
    relatedDocumentsGroupId,
    alerts,
    tangeloCase,
    tangeloShownFile,
    tangeloShownEmail,
    alertsLoading,
    tangeloStatus,
    tangeloCategory,
    selectedDocuments,
  } = props;

  //Document details type
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState(null);
  const [expandedFields, setExpandedFields] = useState({});
  const [extractionFields, setExtractionFields] = useState({});
  const [extractionDropdownOptions, setExtractionDropdownOptions] = useState(
    {}
  );

  //Related documents type
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const [relatedEmails, setRelatedEmails] = useState([]);
  const [shownBrinksFile, setShownBrinksFile] = useState({});
  const [shownBrinksOffice, setShownBrinksOffice] = useState(brinksOffice);
  const [brinksFieldsToShow, setBrinksFieldsToShow] = useState([]);
  const [brinksFieldToUpdate, setBrinksFieldToUpdate] = useState(null);
  const [brinksFieldHovered, setBrinksFieldHovered] = useState(null);
  const [updatingBrinksField, setUpdatingBrinksField] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailLanguage, setEmailLanguage] = useState("es");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [fileInputRef] = useState(React.createRef());
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(false);

  //Tangelo
  const [shownTangeloFile, setShownTangeloFile] = useState(
    tangeloShownFile || {}
  );
  const [shownTangeloEmail, setShownTangeloEmail] = useState(
    tangeloShownEmail || {}
  );
  const [shownTangeloCase, setShownTangeloCase] = useState(tangeloCase);

  //Modals
  const [showSendDocumentsModal, setShowSendDocumentsModal] = useState(false);
  const [showSendTangeloCaseModal, setShowSendTangeloCaseModal] =
    useState(false);
  const [showUpdateBrinksFieldModal, setShowUpdateBrinksFieldModal] =
    useState(true);
  const [showArchivePQRSCaseModal, setShowArchivePQRSCaseModal] =
    useState(false);
  const [archivingPQRSCase, setArchivingPQRSCase] = useState(false);
  const [archiveComment, setArchiveComment] = useState("");
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [currentEmail, setCurrentEmail] = useState(
    props.tangeloCase?.email_remitente || ""
  );

  // const emailAddresses = ["juan.perez@gmail.com", "maria.gomez@gmail.com", "luis.gonzalez@gmail.com"];

  useEffect(() => {
    if (type === "documentDetail") {
      getFile();
    } else if (type === "relatedDocuments") {
      getRelatedDocuments();
    } else if (type === "brinksOfficeDocuments") {
      getBrinksOfficeDocuments();
    } else if (type === "brinksMain") {
      getBrinksOfficeDocuments();
    } else if (type === "alerts") {
      props.getAlerts(relatedDocumentsGroupId);
    } else if (
      type === "tangeloMain" ||
      type === "tangeloCaseDocuments" ||
      type === "tangeloStatus"
    ) {
      getTangeloCaseDocumentsAndEmails();
    }
  }, []);

  useEffect(() => {
    if (type === "documentDetail") {
      getFile();
    }
  }, [selectedRegister]);

  useEffect(() => {
    setShownBrinksFile(brinksShownFile);
  }, [brinksShownFile]);

  useEffect(() => {
    setShownTangeloFile(tangeloShownFile);
  }, [tangeloShownFile]);

  useEffect(() => {
    setShownTangeloEmail(tangeloShownEmail);
  }, [tangeloShownEmail]);

  const getFile = async () => {
    setLoading(true);
    const response = await getDocumentById(
      mainId,
      props.mantisBusiness._id,
      props.token
    );
    if (response.success) {
      setDocument(response.document);
    } else {
      alert("Error al obtener el documento");
    }
    setLoading(false);
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  const handleEmailInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (
        currentEmail.trim() !== "" &&
        !emailAddresses.includes(currentEmail.trim())
      ) {
        setEmailAddresses([...emailAddresses, currentEmail.trim()]);
        setCurrentEmail("");
      }
    }
  };

  const removeEmail = (index) => {
    setEmailAddresses(emailAddresses.filter((_, i) => i !== index));
  };

  const getBrinksOfficeDocuments = async () => {
    setLoading(true);
    const response = await getRelatedDocumentGroup(
      props.token,
      brinksOffice.file_group_id,
      props.mantisBusiness._id
    );
    if (response.success) {
      setRelatedDocuments(response.documents);
      setShownBrinksFile(response.documents[0]);
    }
    const fieldsResponse = await getExtractionFields(
      props.token,
      props.mantisBusiness._id
    );
    if (fieldsResponse.success) {
      setExtractionFields(fieldsResponse.fields);
    }
    setLoading(false);
  };

  const getTangeloCaseDocumentsAndEmails = async () => {
    setLoading(true);
    const response = await getRelatedDocumentGroup(
      props.token,
      tangeloCase.file_group_id,
      props.mantisBusiness._id
    );
    if (response.success) {
      setRelatedDocuments(response.documents);
      setRelatedEmails(response.emails);
      if (response.emails.length > 0) {
        setShownTangeloEmail(response.emails[0]);
        setShownTangeloFile({});
      } else if (response.documents.length > 0) {
        setShownTangeloFile(response.documents[0]);
        setShownTangeloEmail({});
      } else {
        setShownTangeloFile({});
        setShownTangeloEmail({});
      }
    }
    const fieldsResponse = await getExtractionFields(
      props.token,
      props.mantisBusiness._id
    );
    if (fieldsResponse.success) {
      setExtractionFields(fieldsResponse.fields);
    }
    const dropdownOptionsResponse = await getExtractionDropdownOptions(
      props.token,
      props.mantisBusiness._id
    );
    if (dropdownOptionsResponse.success) {
      setExtractionDropdownOptions(dropdownOptionsResponse.options);
    }
    setLoading(false);
  };

  const updateBrinksField = async () => {
    setUpdatingBrinksField(true);
    const response = await updateExtractionField(
      props.token,
      brinksFieldToUpdate.key,
      brinksFieldToUpdate.value,
      brinksOffice.main_key,
      props.mantisBusiness._id
    );
    if (response.success) {
      setUpdatingBrinksField(false);
      setShowUpdateBrinksFieldModal(false);
      setShownBrinksOffice(response.updated_fields);
      props.getAlerts(brinksOffice.file_group_id);
    } else {
      alert("Error al actualizar el campo");
      setUpdatingBrinksField(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const handleAddFilesToRelatedDocumentGroup = async () => {
    setUploadingFiles(true);
    try {
      let uploadedFiles = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        let base64 = await getBase64(selectedFiles[i]);
        base64 = base64.split(",")[1];
        let res = await addDocumentToRelatedDocumentsGroup(
          props.token,
          props.mantisBusiness._id,
          props.user._id,
          base64,
          selectedFiles[i].name,
          selectedFiles[i].type,
          selectedFiles[i].lastModified,
          selectedFiles[i].size,
          tangeloCase.file_group_id
        );
        if (!res.success) {
          throw new Error("Error uploading file");
        } else {
          uploadedFiles.push(res.newDocument);
        }
      }
      // After successful upload:
      setShowUploadModal(false);
      setSelectedFiles([]);
      setRelatedDocuments([...relatedDocuments, ...uploadedFiles]);
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Error al subir los archivos");
    } finally {
      setUploadingFiles(false);
    }
  };

  const getRelatedDocuments = async () => {
    setLoading(true);
    let documentIds = selectedRegisters.map((register) => register.main_id);
    //remove duplicates
    documentIds = [...new Set(documentIds)];
    const relatedDocumentsResponse = await getDocumentsByIds(
      documentIds,
      props.mantisBusiness._id,
      props.token
    );
    if (relatedDocumentsResponse.success) {
      setRelatedDocuments(relatedDocumentsResponse.documents);
    } else {
      alert("Error al obtener los documentos relacionados");
    }
    setLoading(false);
  };

  const handleSendDocumentsEmail = async () => {
    setSendingEmail(true);

    const validEmailAddresses = emailAddresses.filter(
      (email) => email.trim() !== ""
    );

    const response = await sendDocumentsEmail(
      props.token,
      subject,
      message,
      "Oficina Brinks",
      selectedDocuments.map((doc) => doc._id),
      props.mantisBusiness._id,
      validEmailAddresses
    );

    if (response.success) {
      alert("Correo enviado correctamente");
    } else {
      alert("Error al enviar el correo");
    }
    setSendingEmail(false);
    setShowSendDocumentsModal(false);
  };

  const sendTranslatableEmail = async () => {
    setSendingEmail(true);
    const response = await sendTranslatableEmail(
      props.token,
      subject,
      message,
      "Caso Tangelo",
      relatedDocuments.map((doc) => doc._id),
      props.mantisBusiness._id,
      emailAddress,
      emailLanguage
    );
    if (response.success) {
      alert("Correo enviado correctamente");
    } else {
      alert("Error al enviar el correo");
    }
    setSendingEmail(false);
    setShowSendTangeloCaseModal(false);
  };

  const handleRespondPQRSCase = async () => {
    console.log("Respondiendo caso PQRSC...", emailAddress, subject, message);
    setSendingEmail(true);
    try {
      // Validaciones
      if (emailAddresses.length === 0 || !subject || !message) {
        console.log("Error: Campos incompletos");
        toast.error("Por favor complete todos los campos requeridos", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        });
        return;
      }

      // Primero enviamos el correo
      const emailResponse = await respondPqrs(
        props.token,
        props.mantisBusiness._id,
        props.user._id,
        tangeloCase.main_key,
        emailAddresses,
        subject,
        message,
        "Caso PQRSC",
        relatedDocuments.map((doc) => doc._id),
        tangeloCase
      );

      if (!emailResponse.success) {
        throw new Error("Error al enviar el correo");
      }
      // Si el correo se envió correctamente, procedemos a clonar el radicado

      if (tangeloCase.tipo === "salida") {
        const cloneResponse = await cloneRadicado(
          props.token,
          props.mantisBusiness._id,
          props.user._id,
          tangeloCase.main_key,
          {
            emailAddress,
            subject,
            message,
            documentIds: relatedDocuments.map((doc) => doc._id),
            originalCase: tangeloCase,
          }
        );

        if (!cloneResponse.success) {
          throw new Error(
            cloneResponse.message || "Error al clonar el radicado"
          );
        }

        console.log("✅ Respuesta enviada correctamente:", cloneResponse);
      }

      toast.success("Respuesta enviada correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        style: {
          background: "#1e1e1e",
          color: "#fff",
          borderRadius: "4px",
          padding: "16px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
        },
        progressStyle: {
          background: "linear-gradient(to right, #4cd964, #5ac8fa)",
          height: "2px",
          bottom: 0,
        },
      });

      // Actualizar el estado del caso original
      if (props.updateTangeloCase) {
        props.updateTangeloCase(tangeloCase.main_key, "estado", "respondido");
      }

      // Cerrar el modal y limpiar el formulario
      setShowSendTangeloCaseModal(false);
      setEmailAddress("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("❌ Error:", error);
      toast.error(error.message || "Error al procesar la respuesta", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        style: {
          background: "#1e1e1e",
          color: "#fff",
          borderRadius: "4px",
          padding: "16px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
        },
        progressStyle: {
          background: "linear-gradient(to right, #ff3b30, #ff9500)",
          height: "2px",
          bottom: 0,
        },
      });
    } finally {
      setSendingEmail(false);
    }
    setSendingEmail(false);
    setShowSendTangeloCaseModal(false);
  };

  const handleArchivePQRSCase = async () => {
    setArchivingPQRSCase(true);
    try {
      const response = await archivePQRSCase(
        props.token,
        tangeloCase.main_key,
        props.mantisBusiness._id,
        archiveComment,
        props.user._id
      );
      if (response.success) {
        alert("Caso archivado correctamente");
        props.updateTangeloCase(tangeloCase.main_key, "estado", "archivado");
      } else {
        alert("Error al archivar el caso");
      }
      setShowArchivePQRSCaseModal(false);
      setArchiveComment("");
      setArchivingPQRSCase(false);
    } catch (error) {
      alert("Error al archivar el caso");
      setShowArchivePQRSCaseModal(false);
      setArchiveComment("");
      setArchivingPQRSCase(false);
    }
  };
  const snakeToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const toSentenceCase = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <>
      <ToastContainer />
      {type === "documentDetail" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">{title}</h3>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                <Col>
                  <div className="document-analysis-module-fields-container">
                    {Object.keys(selectedRegister).map((key, index) => {
                      if (
                        key !== "main_id" &&
                        key !== "business_id" &&
                        key !== "type"
                      ) {
                        return (
                          <span
                            className="document-analysis-module-field"
                            key={index}
                          >
                            {!selectedRegister[key] && "🟠 "}
                            <strong>{key}</strong>:{" "}
                            {expandedFields[key]
                              ? selectedRegister[key]
                                ? stringify(selectedRegister[key])
                                : "-"
                              : selectedRegister[key]
                              ? shortenText(
                                  stringify(selectedRegister[key]),
                                  50
                                )
                              : "-"}
                            {selectedRegister[key]?.length > 50 && (
                              <>
                                <br />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() =>
                                    setExpandedFields({
                                      ...expandedFields,
                                      [key]: !expandedFields[key],
                                    })
                                  }
                                >
                                  {expandedFields[key]
                                    ? "Ver menos"
                                    : "Ver más"}
                                </span>
                              </>
                            )}
                          </span>
                        );
                      }
                    })}
                  </div>
                </Col>
                {document && (
                  <Col>
                    {/* <img
                  src={document.remoteFile}
                  alt="file"
                  className="document-analysis-module-file"
                  /> */}
                    <iframe
                      src={document.remoteFile}
                      style={{ width: "100%", height: "100%" }}
                      title="file"
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      ) : type === "relatedDocuments" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">
            Documentos relacionados
          </h3>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              relatedDocuments.map((document, index) => (
                <Col
                  key={index}
                  xs={6}
                  onClick={() => {
                    props.setSelectedRegister(
                      selectedRegisters.find(
                        (register) => register.main_id === document._id
                      )
                    );
                  }}
                >
                  <div
                    className={
                      document._id === selectedRegister.main_id
                        ? "document-analysis-module-active-related-document-container"
                        : "document-analysis-module-related-document-container"
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt="file"
                        className="document-analysis-module-related-document-icon"
                      />
                    </div>
                    <h5 className="document-analysis-module-related-document-name">
                      {document.name}
                    </h5>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </div>
      ) : type === "alerts" ? (
        <div div className="document-analysis-module-container-alerts">
          <h3 className="document-analysis-module-title">{title}</h3>
          <Row>
            {props.alertsLoading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                <Col>
                  <div className="document-analysis-module-fields-container">
                    {alerts
                      .filter((alert) => alert.type !== "SUBALERT")
                      .map((alert, index) => (
                        <div key={index}>
                          <span className="document-analysis-module-alert">
                            {alert.type === "INFO"
                              ? "🟢 "
                              : alert.type === "ALERT"
                              ? "🔴 "
                              : "-"}{" "}
                            {alert.message}
                          </span>
                        </div>
                      ))}
                    {alerts.filter((alert) => alert.type === "SUBALERT")
                      .length > 0 && (
                      <div
                        style={{
                          border: "1px solid white",
                          height: "1px",
                          width: "100%",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      ></div>
                    )}
                    {alerts
                      .filter((alert) => alert.type === "SUBALERT")
                      .map((alert, index) => (
                        <div key={index}>
                          <span className="document-analysis-module-alert">
                            {"🟡 "}
                            {alert.message}
                          </span>
                        </div>
                      ))}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      ) : type === "tangeloCasePetitions" ? (
        <div div className="document-analysis-module-container-alerts">
          <h3 className="document-analysis-module-title">Resumen</h3>
          {console.log("INFO :)", tangeloCase)}
          <br />
          <div
            className="document-analysis-module-alert"
            style={{ textAlign: "center", margin: 10 }}
          >
            <span>Identificación</span>
          </div>
          {tangeloCase.main_key && (
            <div className="document-analysis-module-alert">
              No. Radicado: {tangeloCase.main_key}
            </div>
          )}
          {tangeloCase.fecha_radicado && (
            <div className="document-analysis-module-alert">
              Fecha de ingreso: {tangeloCase.fecha_radicado}
            </div>
          )}
          {tangeloCase.tipificacion && (
            <div className="document-analysis-module-alert">
              Tipificación: {toSentenceCase(tangeloCase.tipificacion)}
            </div>
          )}
          {tangeloCase.grupo && (
            <div className="document-analysis-module-alert">
              Grupo: {toSentenceCase(tangeloCase.grupo)}
            </div>
          )}
          {tangeloCase.clase && (
            <div className="document-analysis-module-alert">
              Clase de correspondencia: {toSentenceCase(tangeloCase.clase)}
            </div>
          )}
          {tangeloCase.fuente && (
            <div className="document-analysis-module-alert">
              Medio recepción: {snakeToTitleCase(tangeloCase.fuente)}
            </div>
          )}
          <div
            className="document-analysis-module-alert"
            style={{ textAlign: "center", margin: 10 }}
          >
            <span>Destinatario</span>
          </div>
          {tangeloCase.dependencia && (
            <div className="document-analysis-module-alert">
              Dependencia: {toSentenceCase(tangeloCase.dependencia)}
            </div>
          )}
          {tangeloCase.nombre_usuario && (
            <div className="document-analysis-module-alert">
              Para: {toSentenceCase(tangeloCase.nombre_usuario)}
            </div>
          )}
          {tangeloCase.resumen && (
            <div className="document-analysis-module-alert">
              Asunto: {toSentenceCase(tangeloCase.resumen)}
            </div>
          )}
          {tangeloCase.numero_hojas && (
            <div className="document-analysis-module-alert">
              No. de hojas: {tangeloCase.numero_hojas}
            </div>
          )}
          {tangeloCase.numero_comunicacion_usuario && (
            <div className="document-analysis-module-alert">
              Número de comunicación: {tangeloCase.numero_comunicacion_usuario}
            </div>
          )}
          {tangeloCase.ciudad_destino_usuario && (
            <div className="document-analysis-module-alert">
              Ciudad destino: {tangeloCase.ciudad_destino_usuario}
            </div>
          )}
          {tangeloCase.direccion_usuario && (
            <div className="document-analysis-module-alert">
              Dirección: {tangeloCase.direccion_usuario}
            </div>
          )}
          <div
            className="document-analysis-module-alert"
            style={{ textAlign: "center", margin: 10 }}
          >
            <span>Remitente</span>
          </div>
          {tangeloCase.nombre_solicitante && (
            <div className="document-analysis-module-alert">
              De: {toSentenceCase(tangeloCase.nombre_solicitante)}
            </div>
          )}
          {tangeloCase.tipo_documento_remitente && (
            <div className="document-analysis-module-alert">
              Tipo de documento:{" "}
              {toSentenceCase(tangeloCase.tipo_documento_remitente)}
            </div>
          )}
          {tangeloCase.numero_documento_remitente && (
            <div className="document-analysis-module-alert">
              Documento: {tangeloCase.numero_documento_remitente}
            </div>
          )}
          {tangeloCase.direccion_remitente && (
            <div className="document-analysis-module-alert">
              Dirección: {toSentenceCase(tangeloCase.direccion_remitente)}
            </div>
          )}
          {tangeloCase.email_remitente && (
            <div className="document-analysis-module-alert">
              Email: {tangeloCase.email_remitente}
            </div>
          )}
          {tangeloCase.numero_celular_remitente && (
            <div className="document-analysis-module-alert">
              No. Celular: {tangeloCase.numero_celular_remitente}
            </div>
          )}
          {tangeloCase.pais_remitente && (
            <div className="document-analysis-module-alert">
              País: {toSentenceCase(tangeloCase.pais_remitente)}
            </div>
          )}
          {tangeloCase.ciudad_remitente && (
            <div className="document-analysis-module-alert">
              Ciudad: {toSentenceCase(tangeloCase.ciudad_remitente)}
            </div>
          )}
          {tangeloCase.fecha_remision && (
            <div className="document-analysis-module-alert">
              Fecha remisión: {tangeloCase.fecha_remision}
            </div>
          )}
          {tangeloCase.medio_respuesta && (
            <div className="document-analysis-module-alert">
              Medio respuesta: {toSentenceCase(tangeloCase.medio_respuesta)}
            </div>
          )}
          {tangeloCase.copias_rotulo && (
            <div className="document-analysis-module-alert">
              Copias rotulo: {tangeloCase.copias_rotulo}
            </div>
          )}
          {tangeloCase.rotulo_automatico && (
            <div className="document-analysis-module-alert">
              Rotulo automatico:{" "}
              {tangeloCase.rotulo_automatico === true ? "Sí" : "No"}
            </div>
          )}
          {tangeloCase.rotulo_papel && (
            <div className="document-analysis-module-alert">
              Rotulo papel: {tangeloCase.rotulo_papel === true ? "Sí" : "No"}
            </div>
          )}
          {tangeloCase.requiere_respuesta && (
            <div className="document-analysis-module-alert">
              Requiere respuesta:{" "}
              {tangeloCase.requiere_respuesta === true ? "Sí" : "No"}
            </div>
          )}
          <div
            className="document-analysis-module-alert"
            style={{ textAlign: "center", margin: 10 }}
          >
            <span>Observaciones</span>
          </div>
          {tangeloCase.observaciones && (
            <div className="document-analysis-module-alert">
              {tangeloCase.observaciones}
            </div>
          )}
          {tangeloCase.peticion_1 &&
            tangeloCase.peticion_1 !== "[]" &&
            tangeloCase.peticion_1 !== "null" && (
              <>
                <hr
                  style={{
                    backgroundColor: "white",
                    height: "1px",
                    border: "none",
                    marginBottom: "10px",
                  }}
                />
                <h3 className="document-analysis-module-title">{title}</h3>
                <br />
                <Row>
                  <Col>
                    <div className="document-analysis-module-fields-container">
                      {tangeloCase.peticion_1 &&
                      tangeloCase.peticion_1 !== "[]" &&
                      tangeloCase.peticion_1 !== "null"
                        ? JSON.parse(
                            tangeloCase.peticion_1.replace(/\n/g, "")
                          ).map((petition, index) => (
                            <div key={index}>
                              <span className="document-analysis-module-alert">
                                - {petition}
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          <hr
            style={{
              backgroundColor: "white",
              height: "1px",
              border: "none",
              marginBottom: "10px",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h3 className="document-analysis-module-title">Insights</h3>
            <i
              className="fas fa-lightbulb"
              style={{ fontSize: "20px", marginLeft: "10px", color: "white" }}
            ></i>
          </div>
          <br />
          {tangeloCase.sentimiento && (
            <div className="document-analysis-module-alert">
              - Sentimiento: {tangeloCase.sentimiento}
            </div>
          )}
          {tangeloCase.intencion && (
            <div className="document-analysis-module-alert">
              - Intención: {tangeloCase.intencion}
            </div>
          )}
          {tangeloCase.tono && (
            <div className="document-analysis-module-alert">
              - Tono: {tangeloCase.tono}
            </div>
          )}
          {tangeloCase.edad_aproximada && (
            <div className="document-analysis-module-alert">
              - Edad aproximada: {tangeloCase.edad_aproximada}
            </div>
          )}
          {tangeloCase.genero && (
            <div className="document-analysis-module-alert">
              - Género: {tangeloCase.genero}
            </div>
          )}
          {tangeloCase.nivel_educacion && (
            <div className="document-analysis-module-alert">
              - Nivel de educación: {tangeloCase.nivel_educacion}
            </div>
          )}
          {tangeloCase.estrato_socioeconomico && (
            <div className="document-analysis-module-alert">
              - Estrato socioeconómico: {tangeloCase.estrato_socioeconomico}
            </div>
          )}
        </div>
      ) : type === "tangeloStatus" ? (
        <div div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">{title}</h3>
          <br />
          {!loading ? (
            <Row>
              <Col>
                <div className="document-analysis-module-fields-container">
                  {props.changingStatus ? (
                    <Spinner
                      color="white"
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    <>
                      <Row
                        style={{
                          width: "100%",
                        }}
                      >
                        {tangeloCase.estado !== "archivado" && (
                          <Col
                            xs={6}
                            style={{
                              margin: "auto",
                              marginTop: "20px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              className="business-extraction-filter-input"
                              onClick={() => {
                                setShowArchivePQRSCaseModal(true);
                              }}
                            >
                              <h5
                                style={{
                                  color: "white",
                                  marginRight: "10px",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.8rem",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                Archivar
                              </h5>

                              <i
                                className="fas fa-archive"
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  marginRight: "10px",
                                  fontSize: "20px",
                                }}
                              ></i>
                            </div>
                          </Col>
                        )}
                        {(tangeloCase.estado !== "respondido" ||
                          props.tab === "SENT") && (
                          <Col
                            xs={6}
                            style={{
                              margin: "auto",
                              marginTop: "20px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              className="business-extraction-filter-input"
                              onClick={() => {
                                setShowSendTangeloCaseModal(true);
                                setEmailAddress("");
                                setEmailAddresses([
                                  tangeloCase.email_remitente,
                                ]);
                                setCurrentEmail("");
                                setSubject(
                                  "Caso " +
                                    tangeloCase.main_key +
                                    " - " +
                                    tangeloCase.nombre_solicitante
                                );
                                setMessage(
                                  "Respuesta de comunicado No. " +
                                    tangeloCase.main_key +
                                    " por parte de la dependencia " +
                                    tangeloCase.dependencia +
                                    (tangeloCase.dependencia
                                      ? " a cargo del funcionario " +
                                        tangeloCase.nombre_usuario
                                      : "") +
                                    ". Asunto del caso: " +
                                    tangeloCase.resumen
                                );
                              }}
                            >
                              <h5
                                style={{
                                  color: "white",
                                  marginRight: "10px",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "0.8rem",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                Responder
                              </h5>

                              <i
                                className="fas fa-paper-plane"
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  marginRight: "10px",
                                  fontSize: "20px",
                                }}
                              ></i>
                            </div>
                          </Col>
                        )}
                        {/* <Col xs={6}>
                          <Form>
                            <FormGroup>
                              <Input
                                type="select"
                                name="status"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  props.updateTangeloCase(
                                    tangeloCase.main_key,
                                    "estado",
                                    e.target.value
                                  );
                                }}
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.8rem",
                                  height: "40px",
                                  justifyContent: "center"
                                }}
                                value={tangeloStatus ? tangeloStatus : "-"}
                              >
                                {extractionDropdownOptions.states.map(
                                  (state) => (
                                    <option
                                      value={state.value}
                                      className="business-extraction-select-option"
                                    >
                                      {state.label}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </Form>
                        </Col>

                        <Col xs={6}>
                          <Form>
                            <FormGroup>
                              <Input
                                type="select"
                                name="categoria"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  props.updateTangeloCase(
                                    tangeloCase.main_key,
                                    "categoria",
                                    e.target.value
                                  );
                                }}
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.8rem",
                                  height: "40px",
                                  justifyContent: "center"
                                }}
                                value={
                                  tangeloCategory
                                    ? tangeloCategory
                                    : "SIN CLASIFICAR"
                                }
                              >
                                {extractionDropdownOptions.categories.map(
                                  (state) => (
                                    <option
                                      value={state.value}
                                      className="business-extraction-select-option"
                                    >
                                      {state.label}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </Form>
                        </Col> */}
                      </Row>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
              <Spinner
                color="white"
                style={{
                  alignSelf: "center",
                }}
              />
            </Col>
          )}
        </div>
      ) : type === "brinksMain" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">{title}</h3>
          <Row
            style={{
              height: "90%",
            }}
          >
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                <Col
                  style={{
                    overflow: "scroll",
                    scrollbarWidth: "none",
                  }}
                  xs={5}
                >
                  <div className="document-analysis-module-fields-container">
                    {Object.keys(shownBrinksOffice).map((key, index) => {
                      let fieldsToShow = [];
                      if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["inspector_main_id"]
                      ) {
                        fieldsToShow = extractionFields.inspectorFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cuadre_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cuadreJNFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cuadre_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cuadreJAFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["ido_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.idoJNFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["ido_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.idoJAFields;
                      } else if (
                        shownBrinksFile._id === shownBrinksOffice["doe_main_id"]
                      ) {
                        fieldsToShow = extractionFields.doeFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["codigo_oficina_main_id"]
                      ) {
                        fieldsToShow = extractionFields.codigoOficina;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["provision_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.provisionJNFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["provision_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.provisionJAFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["banco_en_linea_main_id"]
                      ) {
                        fieldsToShow = extractionFields.bancoEnLineaFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["reversa_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.reversaJN;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["reversa_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.reversaJA;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["timbre_sobrante_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.timbreSobranteJN;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["timbre_sobrante_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.timbreSobranteJA;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["transito_americano_main_id"]
                      ) {
                        fieldsToShow = extractionFields.transitoAmericanoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cuadre_americano_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cuadreAmericanoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["remanente_americano_main_id"]
                      ) {
                        fieldsToShow =
                          extractionFields.remanenteAmericanoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_codigo_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoCodigoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_cmc_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoCMCFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_eps_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoEPSFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_clinica_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoClinicaFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["bsafe_main_id"]
                      ) {
                        fieldsToShow = extractionFields.bsafeFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["diario_efectivo_main_id"]
                      ) {
                        fieldsToShow = extractionFields.diarioEfectivoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cierre_pickup_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cierrePickupFields;
                      } else {
                        fieldsToShow = [];
                      }

                      if (
                        fieldsToShow
                          .map((field) => field.fieldName)
                          .includes(key)
                      ) {
                        let friendlyName = fieldsToShow.find(
                          (field) => field.fieldName === key
                        ).friendlyName;
                        return (
                          <span
                            className="document-analysis-module-field"
                            key={index}
                            onMouseEnter={() => {
                              if (
                                checkMantisBusinessUserPermission(
                                  props.user,
                                  "EDIT_TAKTIK"
                                )
                              ) {
                                setBrinksFieldHovered(key);
                              }
                            }}
                            onMouseLeave={() => {
                              setBrinksFieldHovered(null);
                            }}
                          >
                            {!shownBrinksOffice[key] && "🟠 "}
                            <strong>{friendlyName}</strong>:{" "}
                            {expandedFields[key]
                              ? shownBrinksOffice[key]
                                ? stringify(shownBrinksOffice[key])
                                : "-"
                              : shownBrinksOffice[key]
                              ? shortenText(
                                  stringify(shownBrinksOffice[key]),
                                  50
                                )
                              : "-"}
                            {shownBrinksOffice[key]?.length > 50 && (
                              <>
                                <br />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() =>
                                    setExpandedFields({
                                      ...expandedFields,
                                      [key]: !expandedFields[key],
                                    })
                                  }
                                >
                                  {expandedFields[key]
                                    ? "Ver menos"
                                    : "Ver más"}
                                </span>
                              </>
                            )}
                            {brinksFieldHovered === key && (
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setBrinksFieldToUpdate({
                                    key: key,
                                    value: shownBrinksOffice[key],
                                  });
                                  setShowUpdateBrinksFieldModal(true);
                                }}
                              >
                                <i className="fas fa-edit"></i>
                              </span>
                            )}
                          </span>
                        );
                      }
                    })}
                  </div>
                </Col>
                {shownBrinksFile && (
                  <Col xs={7}>
                    {/* <img
                  src={document.remoteFile}
                  alt="file"
                  className="document-analysis-module-file"
                  /> */}
                    {shownBrinksFile.remoteFile.includes("pdf") ? (
                      <iframe
                        src={shownBrinksFile.remoteFile}
                        style={{ width: "100%", height: "100%" }}
                        title="file"
                      />
                    ) : shownBrinksFile.remoteFile.includes("png") ||
                      shownBrinksFile.remoteFile.includes("jpg") ||
                      shownBrinksFile.remoteFile.includes("jpeg") ? (
                      <img
                        src={shownBrinksFile.remoteFile}
                        style={{ width: "100%", height: "100%" }}
                        title="file"
                        alt="file"
                      />
                    ) : (
                      <iframe
                        src={
                          "https://view.officeapps.live.com/op/embed.aspx?src=" +
                          shownBrinksFile.remoteFile
                        }
                        style={{ width: "100%", height: "100%" }}
                        title="file"
                      />
                    )}
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      ) : type === "brinksOfficeDocuments" ? (
        <div className="document-analysis-module-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 className="document-analysis-module-title">
                Documentos relacionados
              </h3>
              <Input
                placeholder="Buscar documento"
                className="document-analysis-module-related-document-checkbox"
                type="checkbox"
                checked={selectedDocuments?.length === relatedDocuments?.length}
                style={{
                  marginLeft: "10px",
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    props.setSelectedDocuments(relatedDocuments);
                  } else {
                    props.setSelectedDocuments([]);
                  }
                }}
              />
            </div>
            {selectedDocuments.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowSendDocumentsModal(true)}
              >
                <h5
                  style={{
                    color: "white",
                    marginRight: "10px",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "0.8rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  Enviar {selectedDocuments.length} documento
                  {selectedDocuments.length > 1 ? "s" : ""}
                </h5>

                <i
                  className="fas fa-paper-plane"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                ></i>
              </div>
            )}
          </div>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              relatedDocuments.map((document, index) => (
                <Col
                  key={index}
                  xs={6}
                  onClick={() => {
                    setShownBrinksFile(document);
                    props.setBrinksShownFile(document);
                  }}
                >
                  <div
                    className={
                      document._id === shownBrinksFile._id
                        ? "document-analysis-module-active-related-document-container"
                        : "document-analysis-module-related-document-container"
                    }
                  >
                    <Input
                      className="document-analysis-module-related-document-checkbox"
                      type="checkbox"
                      checked={selectedDocuments
                        ?.map((doc) => doc._id)
                        .includes(document._id)}
                      style={{
                        marginRight: "10px",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.setSelectedDocuments([
                            ...selectedDocuments,
                            document,
                          ]);
                        } else {
                          props.setSelectedDocuments(
                            selectedDocuments.filter(
                              (doc) => doc._id !== document._id
                            )
                          );
                        }
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          document.remoteFile.includes("pdf")
                            ? pdfIcon
                            : document.remoteFile.includes("png") ||
                              document.remoteFile.includes("jpg") ||
                              document.remoteFile.includes("jpeg")
                            ? imageIcon
                            : document.remoteFile.includes("xlsx") ||
                              document.remoteFile.includes("xls")
                            ? excelIcon
                            : ""
                        }
                        alt="file"
                        className="document-analysis-module-related-document-icon"
                      />
                    </div>
                    <h5 className="document-analysis-module-related-document-name">
                      {document.name}
                    </h5>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </div>
      ) : type === "tangeloCaseDocuments" ? (
        <div className="document-analysis-module-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 className="document-analysis-module-title">
                Elementos relacionados
              </h3>
              <Input
                className="document-analysis-module-related-document-checkbox"
                type="checkbox"
                checked={selectedDocuments.length === relatedDocuments.length}
                style={{
                  marginLeft: "10px",
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    props.setSelectedDocuments(relatedDocuments);
                  } else {
                    props.setSelectedDocuments([]);
                  }
                }}
              />
            </div>
          </div>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                {relatedDocuments.map((document, index) => (
                  <Col
                    key={index}
                    xs={6}
                    onClick={() => {
                      setShownTangeloFile(document);
                      setShownTangeloEmail({});
                      props.setTangeloShownFile(document);
                      props.setTangeloShownEmail({});
                    }}
                  >
                    <div
                      className={
                        document._id === shownTangeloFile._id
                          ? "document-analysis-module-active-related-document-container"
                          : "document-analysis-module-related-document-container"
                      }
                    >
                      <Input
                        className="document-analysis-module-related-document-checkbox"
                        type="checkbox"
                        checked={selectedDocuments
                          ?.map((doc) => doc._id)
                          .includes(document._id)}
                        style={{
                          marginRight: "10px",
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            props.setSelectedDocuments([
                              ...selectedDocuments,
                              document,
                            ]);
                          } else {
                            props.setSelectedDocuments(
                              selectedDocuments.filter(
                                (doc) => doc._id !== document._id
                              )
                            );
                          }
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            document.remoteFile.includes("pdf")
                              ? pdfIcon
                              : document.remoteFile.includes("png") ||
                                document.remoteFile.includes("jpg") ||
                                document.remoteFile.includes("jpeg")
                              ? imageIcon
                              : document.remoteFile.includes("xlsx") ||
                                document.remoteFile.includes("xls")
                              ? excelIcon
                              : ""
                          }
                          alt="file"
                          className="document-analysis-module-related-document-icon"
                        />
                      </div>
                      <h5 className="document-analysis-module-related-document-name">
                        {document.name}
                      </h5>
                    </div>
                  </Col>
                ))}
                {relatedEmails.map((email, index) => (
                  <Col
                    key={index}
                    xs={6}
                    onClick={() => {
                      setShownTangeloEmail(email);
                      setShownTangeloFile({});
                      props.setTangeloShownEmail(email);
                      props.setTangeloShownFile({});
                    }}
                  >
                    <div
                      className={
                        email._id === shownTangeloEmail._id
                          ? "document-analysis-module-active-related-document-container"
                          : "document-analysis-module-related-document-container"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={gmailIcon}
                          alt="file"
                          className="document-analysis-module-related-document-icon"
                          style={{
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                      <h5 className="document-analysis-module-related-document-name">
                        {email.subject}
                      </h5>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Row>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files);
              setSelectedFiles(files);
              setShowUploadModal(true);
              e.target.value = "";
            }}
          />

          <div
            className="upload-files-button"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <i className="fas fa-upload" />
            <span>Subir archivos</span>
          </div>
        </div>
      ) : type === "tangeloMain" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">
            {shownTangeloCase.categoria}: {title}
          </h3>
          <Row
            style={{
              height: "90%",
            }}
          >
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                {Object.keys(shownTangeloFile).length > 0 && (
                  <>
                    <Col
                      style={{
                        overflow: "scroll",
                        scrollbarWidth: "none",
                      }}
                      xs={5}
                    >
                      <div className="document-analysis-module-fields-container">
                        {Object.keys(shownTangeloCase).map((key, index) => {
                          let fieldsToShow = [];
                          if (
                            shownTangeloFile._id ===
                            shownTangeloCase["pqr_main_id"]
                          ) {
                            fieldsToShow = extractionFields.pqrsFields;
                          }

                          if (
                            fieldsToShow
                              .map((field) => field.fieldName)
                              .includes(key)
                          ) {
                            let friendlyName = fieldsToShow.find(
                              (field) => field.fieldName === key
                            ).friendlyName;
                            return (
                              <span
                                className="document-analysis-module-field"
                                key={index}
                              >
                                {!shownTangeloCase[key] && "🟠 "}
                                <strong>{friendlyName}</strong>:{" "}
                                {expandedFields[key]
                                  ? shownTangeloCase[key]
                                    ? stringify(shownTangeloCase[key], false)
                                    : "-"
                                  : shownTangeloCase[key]
                                  ? shortenText(
                                      stringify(shownTangeloCase[key], false),
                                      50
                                    )
                                  : "-"}
                                {shownTangeloCase[key]?.length > 50 && (
                                  <>
                                    <br />
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() =>
                                        setExpandedFields({
                                          ...expandedFields,
                                          [key]: !expandedFields[key],
                                        })
                                      }
                                    >
                                      {expandedFields[key]
                                        ? "Ver menos"
                                        : "Ver más"}
                                    </span>
                                  </>
                                )}
                              </span>
                            );
                          }
                        })}
                      </div>
                    </Col>
                    <Col xs={7}>
                      {/* <img
                  src={document.remoteFile}
                  alt="file"
                  className="document-analysis-module-file"
                  /> */}
                      {shownTangeloFile.remoteFile.includes("pdf") ? (
                        <iframe
                          src={shownTangeloFile.remoteFile}
                          style={{ width: "100%", height: "100%" }}
                          title="file"
                        />
                      ) : shownTangeloFile.remoteFile.includes("png") ||
                        shownTangeloFile.remoteFile.includes("jpg") ||
                        shownTangeloFile.remoteFile.includes("jpeg") ? (
                        <img
                          src={shownTangeloFile.remoteFile}
                          style={{ width: "100%", height: "100%" }}
                          title="file"
                          alt="file"
                        />
                      ) : (
                        <iframe
                          src={
                            "https://view.officeapps.live.com/op/embed.aspx?src=" +
                            shownTangeloFile.remoteFile
                          }
                          style={{ width: "100%", height: "100%" }}
                          title="file"
                        />
                      )}
                    </Col>
                  </>
                )}
                {Object.keys(shownTangeloEmail).length > 0 && (
                  <Col xs={12}>
                    <div className="document-analysis-module-fields-container">
                      <span className="document-analysis-module-field">
                        <strong>Correo electrónico</strong>:{" "}
                        {shownTangeloEmail.senderEmail}
                      </span>
                      <span className="document-analysis-module-field">
                        <strong>Asunto</strong>: {shownTangeloEmail.subject}
                      </span>
                      <span
                        className="document-analysis-module-field"
                        style={{
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <strong>Mensaje</strong>: {shownTangeloEmail.body}
                      </span>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      ) : (
        <></>
      )}
      {showUpdateBrinksFieldModal && brinksFieldToUpdate && (
        <Modal
          isOpen={showUpdateBrinksFieldModal}
          toggle={() => {
            if (!updatingBrinksField) setShowUpdateBrinksFieldModal(false);
          }}
        >
          <ModalHeader>
            Actualizar{" "}
            {
              Object.values(extractionFields)
                .find((field) =>
                  field
                    .map((f) => f.fieldName)
                    .includes(brinksFieldToUpdate.key)
                )
                .find((f) => f.fieldName === brinksFieldToUpdate.key)
                .friendlyName
            }
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={brinksFieldToUpdate.value}
                onChange={(e) =>
                  setBrinksFieldToUpdate({
                    key: brinksFieldToUpdate.key,
                    value: e.target.value,
                  })
                }
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (!updatingBrinksField) updateBrinksField();
              }}
            >
              {updatingBrinksField ? (
                <Spinner color="white" size={"sm"} />
              ) : (
                "Actualizar"
              )}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                if (!updatingBrinksField) setShowUpdateBrinksFieldModal(false);
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showSendDocumentsModal && (
        <Modal
          isOpen={showSendDocumentsModal}
          toggle={() => {
            if (!sendingEmail) {
              setShowSendDocumentsModal(false);
            }
          }}
        >
          <ModalHeader>
            Enviar {selectedDocuments.length}{" "}
            {selectedDocuments.length > 1 ? "documentos" : "documento"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="fieldValue">Correo electrónico</Label>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fieldValue">Asunto</Label>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fieldValue">Mensaje</Label>
              <Input
                type="textarea"
                name="fieldValue"
                id="fieldValue"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
            <ul>
              {selectedDocuments.map((document, index) => (
                <li key={index}>{document.name}</li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (!sendingEmail) {
                  handleSendDocumentsEmail();
                }
              }}
            >
              {sendingEmail ? <Spinner color="white" size={"sm"} /> : "Enviar"}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                if (!sendingEmail) {
                  setShowSendDocumentsModal(false);
                }
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showSendTangeloCaseModal && (
        <Modal
          isOpen={showSendTangeloCaseModal}
          toggle={() => {
            if (!sendingEmail) {
              setShowSendTangeloCaseModal(false);
            }
          }}
          className="business-extraction-modal"
        >
          <ModalHeader className="business-extraction-modal-header">
            <h3
              style={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Responder documento
            </h3>
          </ModalHeader>
          <ModalBody className="business-extraction-modal-body">
            <FormGroup>
              <Label
                for="emailField"
                className="business-extraction-filter-label"
              >
                Correo electrónico
              </Label>
              {/* <Input
                type="text"
                name="emailField"
                id="emailField"
                value={props.tangeloCase.email_remitente}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="business-extraction-filter-input"
              /> */}
              <div className="flex flex-wrap items-center p-2 border-none rounded-md focus-within:ring-2 focus-within:ring-blue-500">
                {emailAddresses.map((email, index) => (
                  <div
                    key={index}
                    className="flex items-center m-1 bg-gray-200 rounded-md overflow-hidden"
                  >
                    <span className="px-2 py-1 text-sm">{email}</span>
                    <Button
                      onClick={() => removeEmail(index)}
                      className="p-1 text-red-500 hover:bg-red-100 focus:outline-none"
                    >
                      &times;
                    </Button>
                  </div>
                ))}
                <Input
                  type="text"
                  value={currentEmail}
                  onChange={handleEmailInputChange}
                  onKeyDown={handleEmailInputKeyDown}
                  placeholder={
                    emailAddresses.length === 0
                      ? "Ingrese correos electrónicos y presione Enter"
                      : currentEmail === ""
                      ? "Ingrese correos electrónicos y presione Enter"
                      : ""
                  }
                  className="business-extraction-filter-input flex-grow min-w-[150px] border-none focus:ring-0 p-2"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label
                for="subjectField"
                className="business-extraction-filter-label"
              >
                Asunto
              </Label>
              <Input
                type="text"
                name="subjectField"
                id="subjectField"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="business-extraction-filter-input"
              />
            </FormGroup>
            <FormGroup>
              <Label
                for="messageField"
                className="business-extraction-filter-label"
              >
                Mensaje
              </Label>
              <Input
                type="textarea"
                name="messageField"
                id="messageField"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="business-extraction-filter-input"
                style={{
                  height: "150px",
                  resize: "none",
                }}
              />
            </FormGroup>
            {/* <FormGroup
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px"
              }}
            >
              <Input
                id="english"
                type="checkbox"
                checked={emailLanguage === "en"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setEmailLanguage("en");
                  } else {
                    setEmailLanguage("es");
                  }
                }}
                className="document-analysis-module-related-document-checkbox"
              />
              <Label
                for="english"
                className="business-extraction-filter-label"
                style={{margin: 0}}
              >
                Inglés
              </Label>
            </FormGroup> */}

            {selectedDocuments.length > 0 && (
              <div
                style={{
                  marginTop: "20px",
                  padding: "10px",
                  backgroundColor: "#282828",
                  borderRadius: "4px",
                }}
              >
                <Label
                  className="business-extraction-filter-label"
                  style={{ marginBottom: "10px" }}
                >
                  Documentos adjuntos:
                </Label>
                <ul style={{ color: "white", marginBottom: 0 }}>
                  {selectedDocuments.map((document, index) => (
                    <li key={index}>{document.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </ModalBody>
          <ModalFooter className="business-extraction-modal-footer">
            <Button
              color="secondary"
              onClick={() => {
                if (!sendingEmail) {
                  setShowSendTangeloCaseModal(false);
                }
              }}
              className="business-extraction-modal-button"
              style={{
                backgroundColor: "#282828",
                color: "white",
                border: "1px solid #444",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                if (!sendingEmail) {
                  handleRespondPQRSCase();
                }
              }}
              className="business-extraction-modal-button"
              disabled={sendingEmail}
              style={{
                backgroundColor: "rgba(240, 245, 108, 0.8)",
                color: "#000",
                border: "none",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {sendingEmail ? <Spinner color="dark" size="sm" /> : "Enviar"}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showArchivePQRSCaseModal && (
        <Modal
          isOpen={showArchivePQRSCaseModal}
          toggle={() => {
            if (!archivingPQRSCase) {
              setShowArchivePQRSCaseModal(false);
              setArchiveComment("");
            }
          }}
          className="business-extraction-modal"
        >
          <ModalHeader className="business-extraction-modal-header">
            <h3
              style={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Archivar caso
            </h3>
          </ModalHeader>
          <ModalBody className="business-extraction-modal-body">
            <FormGroup>
              <Label
                for="fieldValue"
                className="business-extraction-filter-label"
              >
                Comentario
              </Label>
              <Input
                type="textarea"
                name="fieldValue"
                id="fieldValue"
                value={archiveComment}
                onChange={(e) => setArchiveComment(e.target.value)}
                className="business-extraction-filter-input"
                style={{
                  height: "100px",
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="business-extraction-modal-footer">
            <Button
              color="secondary"
              onClick={() => {
                if (!archivingPQRSCase) {
                  setShowArchivePQRSCaseModal(false);
                  setArchiveComment("");
                }
              }}
              className="business-extraction-modal-button"
              style={{
                backgroundColor: "#282828",
                color: "white",
                border: "1px solid #444",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                if (!archivingPQRSCase) handleArchivePQRSCase();
              }}
              className="business-extraction-modal-button"
              disabled={archivingPQRSCase}
              style={{
                backgroundColor: "rgba(240, 245, 108, 0.8)",
                color: "#000",
                border: "none",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {archivingPQRSCase ? (
                <Spinner color="dark" size="sm" />
              ) : (
                "Archivar"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Modal
        isOpen={showUploadModal}
        toggle={() => {
          if (!uploadingFiles) {
            setShowUploadModal(false);
            setSelectedFiles([]);
          }
        }}
        className="business-extraction-modal"
      >
        <ModalHeader className="business-extraction-modal-header">
          <h3
            style={{
              color: "white",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Subir archivos
          </h3>
        </ModalHeader>
        <ModalBody className="business-extraction-modal-body">
          <div
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#282828",
              borderRadius: "4px",
            }}
          >
            <Label
              className="business-extraction-filter-label"
              style={{ marginBottom: "10px" }}
            >
              Archivos seleccionados:
            </Label>
            <ul style={{ color: "white", marginBottom: 0 }}>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter className="business-extraction-modal-footer">
          <Button
            color="secondary"
            onClick={() => {
              if (!uploadingFiles) {
                setShowUploadModal(false);
                setSelectedFiles([]);
              }
            }}
            className="business-extraction-modal-button"
            style={{
              backgroundColor: "#282828",
              color: "white",
              border: "1px solid #444",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              if (!uploadingFiles) {
                handleAddFilesToRelatedDocumentGroup();
              }
            }}
            className="business-extraction-modal-button"
            disabled={uploadingFiles}
            style={{
              backgroundColor: "rgba(240, 245, 108, 0.8)",
              color: "#000",
              border: "none",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {uploadingFiles ? <Spinner color="dark" size="sm" /> : "Subir"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default connect(select)(DocumentAnalysisModule);
