"use client";

import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "./components/ui/card";
import { Button } from "./components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/table";
import { Input } from "./components/ui/input";
import { Skeleton } from "./components/ui/skeleton";
import {
  FileUp,
  Download,
  Folder,
  RefreshCw,
  Search,
  ArrowLeft,
  X,
  Plus,
  FileText,
  Clock,
  Database,
  ChevronRight,
  Eye,
  Mail,
  Send,
} from "lucide-react";
import { fetchFlows, runManualFlow, sendEmailReport, listenToDocumentProcessing } from "../ApiCalls/mantisFlows";
import { connect } from "react-redux";
import {
  getBusinessFlowResults,
  exportFlowResults,
} from "../ApiCalls/mantisDocuments";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { Badge } from "./components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./components/ui/dialog";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

const translations = {
  en: {
    documentManager: "Taktik",
    flows: "Flows",
    noFlowsAvailable: "No flows available",
    selectFlow: "Select a flow to view results",
    noResultsAvailable: "No results available for this flow",
    fileName: "File Name",
    extractionDate: "Extraction Date",
    fileSize: "File Size",
    actions: "Actions",
    downloadExcel: "Download Excel",
    uploadFile: "Upload File",
    loading: "Loading...",
    searchPlaceholder: "Search flows...",
    refreshFlows: "Refresh Flows",
    backToFlows: "Back to Flows",
    lastUpdated: "Last Updated",
    documentsProcessed: "Documents Processed",
    filePreview: "File Preview",
    selectedFiles: "Selected Files",
    cancel: "Cancel",
    runFlow: "Run Flow",
    loading: "Loading...",
    description:
      "Gestiona y visualiza los resultados de tus flujos de documentos",
  },
  es: {
    documentManager: "Gestor de Documentos",
    flows: "Flujos",
    noFlowsAvailable: "No hay flujos disponibles",
    selectFlow: "Seleccione un flujo para ver los resultados",
    noResultsAvailable: "No hay resultados disponibles para este flujo",
    fileName: "Nombre del Archivo",
    extractionDate: "Fecha de Extracción",
    fileSize: "Tamaño del Archivo",
    actions: "Acciones",
    downloadExcel: "Descargar Excel",
    uploadFile: "Subir Archivo",
    loading: "Cargando...",
    searchPlaceholder: "Buscar flujos...",
    refreshFlows: "Actualizar Flujos",
    backToFlows: "Volver a Flujos",
    lastUpdated: "Última Actualización",
    documentsProcessed: "Documentos Procesados",
    filePreview: "Vista previa de archivos",
    selectedFiles: "Archivos seleccionados",
    cancel: "Cancelar",
    runFlow: "Ejecutar flujo",
    loading: "Cargando...",
    description:
      "Gestiona y visualiza los resultados de tus flujos de documentos",
  },
  pt: {
    documentManager: "Gerenciador de Documentos",
    flows: "Fluxos",
    noFlowsAvailable: "Não há fluxos disponíveis",
    selectFlow: "Selecione um fluxo para ver os resultados",
    noResultsAvailable: "Não há resultados disponíveis para este fluxo",
    fileName: "Nome do Arquivo",
    extractionDate: "Data de Extração",
    fileSize: "Tamanho do Arquivo",
    actions: "Ações",
    downloadExcel: "Baixar Excel",
    uploadFile: "Enviar Arquivo",
    loading: "Carregando...",
    searchPlaceholder: "Pesquisar fluxos...",
    refreshFlows: "Atualizar Fluxos",
    backToFlows: "Voltar aos Fluxos",
    lastUpdated: "Última Atualização",
    documentsProcessed: "Documentos Processados",
    filePreview: "Pré-visualização de arquivos",
    selectedFiles: "Arquivos selecionados",
    cancel: "Cancelar",
    runFlow: "Executar fluxo",
    loading: "Carregando...",
    description:
      "Gestiona y visualiza los resultados de tus flujos de documentos",
  },
};

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.3 },
};

const processFlowResults = (results) => {
  console.log("Resultados recibidos:", results);
  const processedResults = [];

  results.forEach((result, index) => {
    console.log(`\nProcesando resultado ${index + 1}:`, result);
    
    const demandadoKeys = result.columns
      .filter((col) => col.name.startsWith("Demandado "))
      .map((col) => parseInt(col.name.split(" ")[1]))
      .filter((num) => !isNaN(num));

    console.log("Demandados encontrados:", demandadoKeys);
    
    const maxDemandados = demandadoKeys.length > 0 ? Math.max(...demandadoKeys) : 0;
    console.log("Número máximo de demandados:", maxDemandados);

    for (let i = 1; i <= maxDemandados; i++) {
      console.log(`\nProcesando demandado ${i}:`);
      
      // Encontrar todas las columnas relacionadas con este demandado
      const columnasDemandado = result.columns.filter(col => 
        col.name === `DPI Demandado ${i}` ||
        col.name === `Demandado ${i}` ||
        col.name === `estado_cuenta_demandado_${i}` ||
        col.name === `saldo_cuenta_demandado_${i}`
      );

      console.log("Columnas del demandado:", columnasDemandado);

      // Verificar estados de envío
      const estadoEnvio = columnasDemandado.length > 0 
        ? columnasDemandado[0].estadoEnvio 
        : "NO ENVIADO";
      
      console.log("Estado de envío inicial:", estadoEnvio);

      const todosEnviados = columnasDemandado.every(col => col.estadoEnvio === estadoEnvio);
      console.log("¿Todas las columnas tienen el mismo estado?", todosEnviados);
      console.log("Estados de envío por columna:", columnasDemandado.map(col => ({
        nombre: col.name,
        estadoEnvio: col.estadoEnvio
      })));

      const demandado = {
        nombre: result.columns.find((col) => col.name === `Demandado ${i}`)?.value,
        dpi: result.columns.find((col) => col.name === `DPI Demandado ${i}`)?.value,
        estado: result.columns.find((col) => col.name === `DPI Demandado ${i}`)?.estado,
        estadoEnvio: todosEnviados ? estadoEnvio : "NO ENVIADO",
        saldoCuenta: result.columns.find(
          (col) => col.name === `saldo_cuenta_demandado_${i}`
        )?.value,
        fecha: result.columns.find((col) => col.name === "Fecha")?.value,
        monto: result.columns.find((col) => col.name === "Monto")?.value,
        porcentaje: result.columns.find((col) => col.name === "Porcentaje")?.value,
        oficio: result.columns.find((col) => col.name === "Oficio")?.value,
        fileUrl: result.fileUrl,
        medioCarga: result.medioCarga,
        _id: result._id
      };

      console.log("Objeto demandado procesado:", demandado);

      if (demandado.nombre) {
        const demandadoFinal = {
          ...demandado,
          saldoEmbargar:
            demandado.saldoCuenta && demandado.porcentaje
              ? ((Number(demandado.saldoCuenta) * Number(demandado.porcentaje)) / 100).toFixed(2)
              : "0.00",
        };
        console.log("Demandado final con saldo a embargar:", demandadoFinal);
        processedResults.push(demandadoFinal);
      }
    }
  });
  
  console.log("\nResultados procesados alejo vea:", processedResults);
  console.log("Resultados procesados:", processedResults);
  processedResults.reverse();
  return processedResults;
};

function DocumentManager(props) {
  const navigate = useNavigate();
  const [flows, setFlows] = useState([]);
  const [filteredFlows, setFilteredFlows] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [flowResults, setFlowResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [runningWorkflow, setRunningWorkflow] = useState(false);
  const [allColumns, setAllColumns] = useState([]);
  const fileInputRef = useRef(null);
  const [documentSearchTerm, setDocumentSearchTerm] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem('documentFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      estado: "all",
      medioCarga: "all",
      oficio: "all"
    };
  });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailForm, setEmailForm] = useState({
    toEmails: "",
    subject: "",
    message: "",
    recipientName: "",
  });
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [processingDocuments, setProcessingDocuments] = useState([]);
  const [processingData, setProcessingData] = useState(false);
  const [processingRows, setProcessingRows] = useState(new Set());
  const previousResultsLength = useRef(flowResults.length);

  const t = translations[props.language] || translations.en;

  useEffect(() => {
    loadFlows();
  }, []);

  useEffect(() => {
    const filtered = flows.filter((flow) =>
      flow.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFlows(filtered);
  }, [searchTerm, flows]);

  useEffect(() => {
    const handleLoading = (event) => {
      setIsLoading(event.detail);
    };

    window.addEventListener('flowsLoading', handleLoading);

    return () => {
      window.removeEventListener('flowsLoading', handleLoading);
    };
  }, []);

  useEffect(() => {
    // Verificar si se agregaron nuevos resultados
    if (flowResults.length > previousResultsLength.current) {
      // Encontrar y remover las filas que ya no están procesando
      setProcessingRows(prev => {
        const newSet = new Set(prev);
        const newResults = flowResults.slice(previousResultsLength.current);
        newResults.forEach(result => {
          newSet.delete(result.dpi); // o cualquier identificador único
        });
        return newSet;
      });
      previousResultsLength.current = flowResults.length;
    }
  }, [flowResults]);

  useEffect(() => {
    localStorage.setItem('documentFilters', JSON.stringify(filters));
  }, [filters]);

  const loadFlows = async () => {
    try {
      setIsLoading(true);
      const fetchedFlows = await fetchFlows(
        props.mantisBusiness._id,
        props.token
      );
      console.log("Fetched flows:", fetchedFlows);
      setFlows(fetchedFlows || []);
      setFilteredFlows(fetchedFlows || []);
    } catch (error) {
      console.error("Error loading flows:", error);
      setFlows([]);
      setFilteredFlows([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedFlow) return;

    const eventSourceHandler = listenToDocumentProcessing(
      selectedFlow._id,
      {
        onProcessing: (data) => {
          console.log("Recibida notificación:", data);

          if (data.type === 'DOCUMENT_PROCESSING') {
            if (data.status === 'STARTED' && data.files) {
              // Activar skeleton para cada archivo
              setProcessingData(true);
              const newRows = new Set();
              data.files.forEach(file => {
                newRows.add(file.name);
                toast.info(`Procesando: ${file.name}`, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "dark"
                });
              });
              setProcessingRows(newRows);
            }
            
            if (data.status === 'COMPLETED') {
              toast.success(data.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "dark"
              });
              
              // Esperar un momento antes de limpiar el skeleton
              setTimeout(() => {
                setProcessingData(false);
                setProcessingRows(new Set());
                // Recargar resultados
                handleFlowSelect(selectedFlow);
              }, 1000);
            }
          }
        },
        onError: (error) => {
          console.error('Error en la conexión SSE:', error);
          setProcessingData(false);
          setProcessingRows(new Set());
        }
      }
    );

    return () => eventSourceHandler.close();
  }, [selectedFlow]);

  useEffect(() => {
    const handleSSE = (event) => {
      const data = JSON.parse(event.data);
      // Procesar los datos manteniendo los filtros actuales
      const processedData = processFlowResults(data);
      setFlowResults(prev => {
        console.log("Actualizando resultados con filtros:", filters);
        return processedData;
      });
    };

    // ... resto del código SSE ...
  }, [filters]);

  const handleFlowSelect = async (flow) => {
    setSelectedFlow(flow);
    if (!flow) return;

    try {
      setIsLoading(true);

      // Crear la conexión SSE
      const eventSource = getBusinessFlowResults(
        props.mantisBusiness._id,
        flow._id
      );

      // Manejar los mensajes entrantes
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.success) {
          const processedResults = processFlowResults(data.flowResults || []);
          setFlowResults(processedResults);
        } else {
          console.error("Error en SSE:", data.message);
        }
        setIsLoading(false);
      };

      // Manejar errores
      eventSource.onerror = (error) => {
        console.error("Error en la conexión SSE:", error);
        eventSource.close();
        setIsLoading(false);
      };

      // Limpiar el EventSource cuando el componente se desmonte
      return () => {
        eventSource.close();
      };
    } catch (error) {
      console.error("Error al configurar SSE:", error);
      setFlowResults([]);
      setIsLoading(false);
    }
  };

  const handleDownloadExcel = async () => {
    if (!selectedFlow || !flowResults.length) return;
    setIsDownloading(true);

    try {
      // Apply the same filters as the table view
      const filteredResults = flowResults.filter((result) => {
        const matchesSearch =
          result.nombre
            ?.toLowerCase()
            .includes(documentSearchTerm.toLowerCase()) ||
          result.dpi?.includes(documentSearchTerm) ||
          documentSearchTerm === "";

        const isMassiveLoad =
          !result.fecha &&
          !result.estado &&
          !result.monto &&
          !result.oficio &&
          !result.porcentaje;
        const isExistente = ["ACTIVO", "INACTIVO", "BLOQUEADO"].includes(
          result.estado?.toUpperCase()
        );

        const matchesEstado =
          filters.estado === "all" ||
          (filters.estado === "existente" && isExistente) ||
          (filters.estado === "inexistente" && !isExistente);

        const matchesMedioCarga =
          filters.medioCarga === "all" ||
          (filters.medioCarga === "masiva" && isMassiveLoad) ||
          (filters.medioCarga === "manual" && !isMassiveLoad);

        return matchesSearch && matchesEstado && matchesMedioCarga;
      });

      // Convert filtered data to export format
      const dataToExport = filteredResults.map((result) => ({
        DPI: result.dpi || "-",
        Nombre: result.nombre || "-",
        Oficio: result.oficio || "-",
        Fecha: result.fecha || "Pendiente",
        Estado: ["ACTIVO", "INACTIVO", "BLOQUEADO"].includes(
          result.estado?.toUpperCase()
        )
          ? "EXISTENTE"
          : "INEXISTENTE",
        Monto: result.monto ? `Q ${result.monto}` : "Pendiente",
        Porcentaje: result.porcentaje ? `${result.porcentaje}%` : "Pendiente",
        "Saldo en Cuenta": result.saldoCuenta ? `Q ${result.saldoCuenta}` : "-",
        "Saldo a Embargar": result.saldoEmbargar
          ? `Q ${result.saldoEmbargar}`
          : "-",
        "Medio de Carga":
          !result.fecha && !result.estado && !result.monto && !result.porcentaje
            ? "Carga masiva"
            : "Carga manual",
      }));

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "Resultados");
      XLSX.writeFile(wb, `${selectedFlow.name}_resultados.xlsx`);
    } catch (error) {
      console.error("Error al generar Excel:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleFileUpload = useCallback((event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setShowFilePreview(true);
  }, []);

  const removeFile = useCallback((fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  }, []);

  const handleAddMoreFiles = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleCancelUpload = useCallback(() => {
    setSelectedFiles([]);
    setShowFilePreview(false);
  }, []);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRunFlow = useCallback(async () => {
    if (runningWorkflow) return;
    setRunningWorkflow(true);
    let files = [];
    for (const file of selectedFiles) {
      let base64 = await fileToBase64(file);
      files.push({
        filename: file.name,
        mimeType: file.type,
        base64: base64.split(",")[1],
        size: file.size,
      });
    }
    const runRes = await runManualFlow(
      selectedFlow._id,
      "672e82bf3ab5c40012235316",
      files
    );
    console.log("Run flow response:", runRes);
    if (runRes.success) {
      setSelectedFiles([]);
      setShowFilePreview(false);
      handleFlowSelect(selectedFlow);
      alert("Flow executed successfully. Check the results in a few momments.");
    } else {
      alert("Error running the flow. Please try again.");
    }
    setRunningWorkflow(false);
  }, [selectedFiles]);

  const handleRowClick = (record) => {
    console.log("1. Registro completo:", record);
    console.log("2. URL del archivo:", record.fileUrl);
    setSelectedRecord(record);
    setShowDetails(true);
  };

  const handleSendEmail = async () => {
    if (!selectedFlow || !flowResults.length) return;
    setIsSendingEmail(true);

    try {
      // Filtrar los resultados como antes
      const filteredResults = flowResults.filter((result) => {
        const matchesSearch =
          result.nombre
            ?.toLowerCase()
            .includes(documentSearchTerm.toLowerCase()) ||
          result.dpi?.includes(documentSearchTerm) ||
          documentSearchTerm === "";

        const isMassiveLoad =
          !result.fecha &&
          !result.estado &&
          !result.monto &&
          !result.oficio &&
          !result.porcentaje;
        const isExistente = ["ACTIVO", "INACTIVO", "BLOQUEADO"].includes(
          result.estado?.toUpperCase()
        );

        const matchesEstado =
          filters.estado === "all" ||
          (filters.estado === "existente" && isExistente) ||
          (filters.estado === "inexistente" && !isExistente);

        const matchesMedioCarga =
          filters.medioCarga === "all" ||
          (filters.medioCarga === "masiva" && isMassiveLoad) ||
          (filters.medioCarga === "manual" && !isMassiveLoad);

        return matchesSearch && matchesEstado && matchesMedioCarga;
      });

      // Preparar los datos para el Excel
      const dataToExport = filteredResults.map((result) => ({
        DPI: result.dpi || "-",
        Nombre: result.nombre || "-",
        Oficio: result.oficio || "-",
        Fecha: result.fecha || "Pendiente",
        Estado: ["ACTIVO", "INACTIVO", "BLOQUEADO"].includes(
          result.estado?.toUpperCase()
        )
          ? "EXISTENTE"
          : "INEXISTENTE",
        Monto: result.monto ? `Q ${result.monto}` : "Pendiente",
        Porcentaje: result.porcentaje ? `${result.porcentaje}%` : "Pendiente",
        "Saldo en Cuenta": result.saldoCuenta ? `Q ${result.saldoCuenta}` : "-",
        "Saldo a Embargar": result.saldoEmbargar
          ? `Q ${result.saldoEmbargar}`
          : "-",
        "Medio de Carga":
          !result.fecha && !result.estado && !result.monto && !result.porcentaje
            ? "Carga masiva"
            : "Carga manual",
      }));

      // Convertir a Excel y obtener base64
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "Resultados");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "base64" });

      // Preparar datos para el envío
      const emailData = {
        toEmails: emailForm.toEmails.split(",").map((email) => email.trim()),
        subject: emailForm.subject,
        message: emailForm.message,
        attachments: [
          {
            mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            base64: excelBuffer,
            filename: `${selectedFlow.name}_resultados.xlsx`,
          },
        ],
      };

      // Obtener los IDs de los registros filtrados
      const recordIds = filteredResults.map(result => result._id);

      // Enviar correo y actualizar estado
      await sendEmailReport(emailData, selectedFlow._id, recordIds);

      setShowEmailModal(false);
      setEmailForm({
        toEmails: "",
        subject: "",
        message: "",
      });
      
      toast.success("Correo enviado exitosamente");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al enviar el correo");
    } finally {
      setIsSendingEmail(false);
    }
  };

  const ProcessingRowSkeleton = ({ fileName, index }) => (
    <TableRow className="relative">
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-8 animate-pulse">
          {index + 1}
        </div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-48 animate-pulse">
          {fileName}
        </div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <motion.div 
          className="h-6 px-3 bg-blue-600/20 rounded-full flex items-center justify-center"
          animate={{
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <span className="text-blue-400 text-sm">Procesando...</span>
        </motion.div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-20 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-16 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <motion.div 
        className="absolute bottom-0 left-0 h-0.5 bg-blue-600"
        initial={{ width: "0%" }}
        animate={{ width: "100%" }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </TableRow>
  );

  // Agregar el componente LoadingSkeleton
  const LoadingSkeleton = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {[1, 2, 3].map((index) => (
        <motion.div
          key={index}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="bg-[#1a1a1a] rounded-lg p-4 animate-pulse">
            <div className="flex items-start justify-between mb-3">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-[#2a2a2a] rounded-md">
                  <div className="h-5 w-5 bg-[#3a3a3a] rounded"></div>
                </div>
                <div>
                  <div className="h-4 bg-[#2a2a2a] rounded w-32 mb-2"></div>
                  <div className="h-3 bg-[#2a2a2a] rounded w-48"></div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <div className="flex items-center gap-2">
                <div className="h-4 bg-[#2a2a2a] rounded w-24"></div>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-4 bg-[#2a2a2a] rounded w-32"></div>
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );

  // Función para obtener oficios únicos
  const getUniqueOficios = useMemo(() => {
    const oficios = flowResults
      .map(result => result.oficio)
      .filter(oficio => oficio) // Filtrar valores nulos o vacíos
      .filter((oficio, index, self) => self.indexOf(oficio) === index) // Eliminar duplicados
      .sort(); // Ordenar alfabéticamente
    
    return oficios;
  }, [flowResults]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-black"
    >
      <ToastContainer />
      <div className="container mx-auto px-6 py-8">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-2xl font-medium text-gray-200 text-left">
              {t.documentManager}
            </h1>
            <p className="text-gray-500 mt-1 text-sm">{t.description}</p>
          </div>
          <div className="flex items-center gap-3">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
              <Input
                placeholder="Buscar flujos..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-[300px] pl-10 bg-[#1a1a1a] border-0 text-gray-200 
                  placeholder-gray-500 focus:ring-1 focus:ring-[#f0f56c]/20 h-9
                  hover:bg-[#2a2a2a] transition-colors"
              />
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={loadFlows}
              className="text-gray-400 hover:text-[#f0f56c] hover:bg-[#1a1a1a] h-9"
            >
              <RefreshCw className="h-4 w-4" />
            </Button>
          </div>
        </div>

        {!selectedFlow ? (
          <div className="space-y-6">
            {isLoading ? (
              <LoadingSkeleton />
            ) : filteredFlows.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filteredFlows.map((flow) => (
                  <motion.div
                    key={flow._id}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div
                      onClick={() => handleFlowSelect(flow)}
                      className="bg-[#1a1a1a] rounded-lg p-4 cursor-pointer
                        hover:bg-[#2a2a2a] transition-all duration-200 group"
                    >
                      <div className="flex items-start justify-between mb-3">
                        <div className="flex items-center gap-3">
                          <div
                            className="p-2 bg-[#2a2a2a] rounded-md 
                            group-hover:bg-[#333] transition-colors"
                          >
                            <Folder className="h-5 w-5 text-[#f0f56c]" />
                          </div>
                          <div>
                            <h3 className="text-gray-200 font-medium">
                              {flow.name}
                            </h3>
                            <p className="text-gray-500 text-sm mt-0.5">
                              {flow.description || "Sin descripción"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 mt-4">
                        <div className="flex items-center gap-2 text-gray-400 text-sm">
                          <FileText className="h-4 w-4" />
                          <span>{flow.documentsProcessed || 0} documentos</span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-400 text-sm">
                          <Clock className="h-4 w-4" />
                          <span>
                            {new Date(flow.updatedAt).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-16 bg-[#1a1a1a] rounded-lg">
                <Folder className="h-16 w-16 text-gray-600 mb-4" />
                <p className="text-gray-400 text-lg">
                  No se encontraron flujos disponibles
                </p>
                <p className="text-gray-500 text-sm mt-2">
                  Intenta ajustar tu búsqueda o refresca la página
                </p>
              </div>
            )}
          </div>
        ) : (
          <Card className="bg-[#1a1a1a] border-0 shadow-lg">
            <CardHeader className="border-b border-[#333]">
              <div className="flex items-center justify-between">
                <CardTitle className="text-gray-200">
                  {selectedFlow.name}
                </CardTitle>
                <div className="relative w-64">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                  <Input
                    placeholder="Buscar demandados..."
                    value={documentSearchTerm}
                    onChange={(e) => setDocumentSearchTerm(e.target.value)}
                    className="pl-10 bg-[#2a2a2a] border-0 text-gray-200 
                      placeholder-gray-500 focus:ring-1 focus:ring-[#f0f56c]/20"
                  />
                </div>
              </div>
            </CardHeader>
            <CardContent className="p-0">
              <div className="p-4 border-b border-[#333] bg-[#1a1a1a]">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-400">Estado:</span>
                    <select
                      className="bg-[#2a2a2a] border-0 rounded-md text-sm text-gray-200 focus:ring-1 focus:ring-[#f0f56c]/20"
                      value={filters.estado}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFilters(prev => {
                          const newFilters = {
                            ...prev,
                            estado: newValue,
                          };
                          console.log("Nuevos filtros:", newFilters);
                          return newFilters;
                        });
                      }}
                    >
                      <option value="all">Todos</option>
                      <option value="existente">Existente</option>
                      <option value="inexistente">Inexistente</option>
                    </select>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-400">
                      Medio de carga:
                    </span>
                    <select
                      className="bg-[#2a2a2a] border-0 rounded-md text-sm text-gray-200 focus:ring-1 focus:ring-[#f0f56c]/20"
                      value={filters.medioCarga}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          medioCarga: e.target.value,
                        }))
                      }
                    >
                      <option value="all">Todos</option>
                      <option value="masiva">Carga masiva</option>
                      <option value="manual">Carga manual</option>
                      <option value="compilada">Carga compilada</option>
                    </select>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-400">Oficio:</span>
                    <select
                      className="bg-[#2a2a2a] border-0 rounded-md text-sm text-gray-200 focus:ring-1 focus:ring-[#f0f56c]/20"
                      value={filters.oficio}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          oficio: e.target.value,
                        }))
                      }
                    >
                      <option value="all">Todos</option>
                      {getUniqueOficios.map((oficio) => (
                        <option key={oficio} value={oficio}>
                          {oficio}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="ml-auto flex gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleDownloadExcel}
                      disabled={isDownloading}
                      className="text-gray-400 hover:text-[#f0f56c] hover:bg-[#1a1a1a]"
                    >
                      {isDownloading ? (
                        <RefreshCw className="h-4 w-4 animate-spin mr-2" />
                      ) : (
                        <Download className="h-4 w-4 mr-2" />
                      )}
                      Exportar Excel
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setShowEmailModal(true)}
                      className="text-gray-400 hover:text-[#f0f56c] hover:bg-[#1a1a1a]"
                    >
                      <Mail className="h-4 w-4 mr-2" />
                      Enviar Reporte
                    </Button>
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-[#2a2a2a] border-b border-[#333]">
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-400 uppercase bg-[#1a1a1a]">
                      <th className="px-4 py-3">#</th>
                      <th className="px-4 py-3">Oficio</th>
                      <th className="px-4 py-3">DPI</th>
                      <th className="px-4 py-3">Nombre</th>
                      <th className="px-4 py-3">Fecha</th>
                      <th className="px-4 py-3">Estado</th>
                      <th className="px-4 py-3">Monto</th>
                      <th className="px-4 py-3">Porcentaje</th>
                      <th className="px-4 py-3">Saldo en cuenta</th>
                      <th className="px-4 py-3">Saldo a embargar</th>
                      <th className="px-4 py-3">Medio de carga</th>
                      <th className="px-4 py-3">Estado de Envío</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#333]">
                    <AnimatePresence>
                      {processingData && Array.from(processingRows).map((fileName, index) => (
                        <ProcessingRowSkeleton key={fileName} fileName={fileName} index={index} />
                      ))}
                    </AnimatePresence>
                    {flowResults
                      .filter((result) => {
                        const matchesSearch =
                          result.nombre
                            ?.toLowerCase()
                            .includes(documentSearchTerm.toLowerCase()) ||
                          result.dpi?.includes(documentSearchTerm) ||
                          documentSearchTerm === "";

                        const matchesEstado =
                          filters.estado === "all" ||
                          (filters.estado === "existente" && result.estado?.trim() === "EXISTENTE") ||
                          (filters.estado === "inexistente" && result.estado?.trim() !== "EXISTENTE");

                        const matchesMedioCarga =
                          filters.medioCarga === "all" ||
                          (filters.medioCarga === "masiva" && result.medioCarga === "CARGA MASIVA") ||
                          (filters.medioCarga === "manual" && result.medioCarga === "CARGA MANUAL") ||
                          (filters.medioCarga === "compilada" && result.medioCarga === "CARGA COMPILADA");

                        const matchesOficio =
                          filters.oficio === "all" ||
                          result.oficio === filters.oficio;

                        return matchesSearch && matchesEstado && matchesMedioCarga && matchesOficio;
                      })
                      .map((result, index) => {
                        // Memorizamos el estado para evitar el parpadeo
                        const estado = result.estado?.trim() || "INEXISTENTE";
                        const isExistente = estado === "EXISTENTE";

                        return (
                          <motion.tr
                            key={index}
                            className="hover:bg-[#2a2a2a] transition-colors cursor-pointer"
                            onClick={() => handleRowClick(result)}
                          >
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {index + 1}
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.oficio || "-"}
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.dpi || "-"}
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.nombre || "-"}
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.fecha || "-"}
                            </td>
                            <td className="px-4 py-3 text-sm">
                              <Badge
                                variant={isExistente ? "success" : "default"}
                              >
                                {estado}
                              </Badge>
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.monto ? `Q ${result.monto}` : "-"}
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.porcentaje ? `${result.porcentaje}%` : "-"}
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.saldoCuenta ? `Q ${result.saldoCuenta}` : "-"}
                            </td>
                            <td className="px-4 py-3 text-sm text-gray-300">
                              {result.saldoEmbargar ? `Q ${result.saldoEmbargar}` : "-"}
                            </td>
                            <td className="px-4 py-3 text-sm">
                              <Badge
                                className={`${
                                  result.medioCarga === "CARGA MASIVA"
                                    ? "bg-orange-500/10 text-orange-500 hover:bg-orange-500/20"
                                    : result.medioCarga === "CARGA COMPILADA"
                                    ? "bg-green-500/10 text-green-500 hover:bg-green-500/20"
                                    : "bg-blue-500/10 text-blue-500 hover:bg-blue-500/20"
                                }`}
                              >
                                {result.medioCarga === "CARGA MASIVA" 
                                  ? "Carga masiva" 
                                  : result.medioCarga === "CARGA COMPILADA"
                                  ? "Carga compilada"
                                  : "Carga manual"}
                              </Badge>
                            </td>
                            <td className="px-4 py-3 text-sm">
                              <Badge
                                variant={result.estadoEnvio === "ENVIADO" ? "success" : "default"}
                                className={`${
                                  result.estadoEnvio === "ENVIADO"
                                    ? "bg-green-500/10 text-green-500 hover:bg-green-500/20"
                                    : "bg-gray-500/10 text-gray-500 hover:bg-gray-500/20"
                                }`}
                              >
                                {result.estadoEnvio}
                              </Badge>
                            </td>
                          </motion.tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        )}
        {showFilePreview && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center p-4"
          >
            <Card className="w-full max-w-md">
              <CardHeader>
                <CardTitle>{t.filePreview}</CardTitle>
                <CardDescription>
                  {t.selectedFiles}: {selectedFiles.length}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-2 mb-4 max-h-60 overflow-y-auto">
                  {selectedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between bg-muted p-2 rounded"
                    >
                      <span className="truncate">{file.name}</span>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => removeFile(file)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between items-center mb-4">
                  <Button
                    variant="outline"
                    onClick={handleAddMoreFiles}
                    className="flex items-center"
                  >
                    <Plus className="h-4 w-4" />
                    {t.addMoreFiles}
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileUpload}
                    multiple
                  />
                </div>
                <div className="flex justify-between">
                  <Button variant="outline" onClick={handleCancelUpload}>
                    {t.cancel}
                  </Button>
                  <Button onClick={handleRunFlow}>
                    {runningWorkflow ? t.loading : t.runFlow}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        )}
      </div>

      {/* Modal de detalles */}
      <Dialog open={showDetails} onOpenChange={setShowDetails}>
        <DialogContent className="max-w-7xl h-[90vh] p-0 bg-[#1a1a1a] border-[#333] overflow-hidden">
          <DialogHeader className="p-6 border-b border-[#333] shrink-0">
            <DialogTitle className="text-xl font-semibold text-gray-200">
              Detalles del Documento
            </DialogTitle>
          </DialogHeader>

          <div className="flex h-[calc(90vh-5rem)] overflow-hidden">
            {/* Panel izquierdo con detalles */}
            <div className="w-1/4 p-6 border-r border-[#333] overflow-y-auto">
              <div className="space-y-6">
                <div>
                  <h3 className="text-sm font-medium text-gray-400">Oficio</h3>
                  <p className="text-gray-200 text-base mt-1">
                    {selectedRecord?.oficio || "-"}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">Nombre</h3>
                  <p className="text-gray-200 text-base mt-1">
                    {selectedRecord?.nombre || "-"}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">DPI</h3>
                  <p className="text-gray-200 text-base mt-1">
                    {selectedRecord?.dpi || "-"}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">Fecha</h3>
                  {!selectedRecord?.fecha ? (
                    <Badge variant="default" className="mt-2">
                      Pendiente
                    </Badge>
                  ) : (
                    <p className="text-gray-200 text-base mt-1">
                      {selectedRecord?.fecha}
                    </p>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">Estado</h3>
                  <div className="mt-2">
                    <Badge
                      variant={selectedRecord?.estado?.trim() === "EXISTENTE" ? "success" : "default"}
                      className="text-sm"
                    >
                      {selectedRecord?.estado?.trim() || "INEXISTENTE"}
                    </Badge>
                  </div>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">Monto</h3>
                  {!selectedRecord?.monto ? (
                    <Badge variant="default" className="mt-2">
                      Pendiente
                    </Badge>
                  ) : (
                    <p className="text-gray-200 text-lg font-semibold mt-1">
                      Q {selectedRecord?.monto}
                    </p>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">
                    Porcentaje
                  </h3>
                  {!selectedRecord?.porcentaje ? (
                    <Badge variant="default" className="mt-2">
                      Pendiente
                    </Badge>
                  ) : (
                    <p className="text-gray-200 text-lg font-semibold mt-1">
                      {selectedRecord?.porcentaje}%
                    </p>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">
                    Saldo en Cuenta
                  </h3>
                  <p className="text-gray-200 text-lg font-semibold mt-1">
                    {selectedRecord?.saldoCuenta
                      ? `Q ${selectedRecord.saldoCuenta}`
                      : "-"}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">
                    Saldo a Embargar
                  </h3>
                  <p className="text-gray-200 text-lg font-semibold mt-1">
                    {selectedRecord?.saldoEmbargar
                      ? `Q ${selectedRecord.saldoEmbargar}`
                      : "-"}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400">
                    Medio de Carga
                  </h3>
                  <Badge
                    variant="outline"
                    className={`mt-2 ${
                      !selectedRecord?.fecha &&
                      !selectedRecord?.estado &&
                      !selectedRecord?.monto &&
                      !selectedRecord?.porcentaje
                        ? "bg-orange-500/10 text-orange-500 hover:bg-orange-500/20"
                        : "bg-blue-500/10 text-blue-500 hover:bg-blue-500/20"
                    }`}
                  >
                    {!selectedRecord?.fecha &&
                    !selectedRecord?.estado &&
                    !selectedRecord?.monto &&
                    !selectedRecord?.porcentaje
                      ? "Carga masiva"
                      : "Carga manual"}
                  </Badge>
                </div>
              </div>
            </div>

            {/* Panel derecho con el visor de PDF */}
            <div className="flex-1 bg-[#141414] relative flex flex-col overflow-hidden">
              {selectedRecord?.fileUrl ? (
                <>
                  <div className="flex-1 overflow-hidden">
                    <iframe
                      src={selectedRecord.fileUrl}
                      className="w-full h-full"
                      title="Documento PDF"
                      style={{ border: "none" }}
                      onLoad={(e) =>
                        console.log("3. iframe cargado:", e.target.src)
                      }
                      onError={(e) => console.log("Error al cargar iframe:", e)}
                    />
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <div className="text-center space-y-4">
                    <FileText className="w-16 h-16 text-gray-500 mx-auto" />
                    <p className="text-gray-500">No hay documento disponible</p>
                    <p className="text-xs text-gray-600">URL no encontrada</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Agregar el modal de envío de correo */}
      <Dialog open={showEmailModal} onOpenChange={setShowEmailModal}>
        <DialogContent className="bg-[#1a1a1a] border-[#333]">
          <DialogHeader>
            <DialogTitle className="text-gray-200">
              Enviar Reporte por Correo
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label className="text-sm text-gray-400">
                Correos (separados por coma)
              </label>
              <Input
                value={emailForm.toEmails}
                onChange={(e) =>
                  setEmailForm((prev) => ({
                    ...prev,
                    toEmails: e.target.value,
                  }))
                }
                placeholder="correo1@ejemplo.com, correo2@ejemplo.com"
                className="bg-[#2a2a2a] border-0 text-gray-200"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm text-gray-400">Asunto</label>
              <Input
                value={emailForm.subject}
                onChange={(e) =>
                  setEmailForm((prev) => ({ ...prev, subject: e.target.value }))
                }
                placeholder="Asunto del correo"
                className="bg-[#2a2a2a] border-0 text-gray-200"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm text-gray-400">Mensaje</label>
              <textarea
                value={emailForm.message}
                onChange={(e) =>
                  setEmailForm((prev) => ({ ...prev, message: e.target.value }))
                }
                placeholder="Mensaje del correo"
                className="w-full h-32 px-3 py-2 bg-[#2a2a2a] border-0 rounded-md text-gray-200 
                  placeholder-gray-500 focus:ring-1 focus:ring-[#f0f56c]/20 resize-none"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowEmailModal(false)}
              className="text-gray-400"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSendEmail}
              disabled={isSendingEmail}
              className="bg-[#f0f56c] text-black hover:bg-[#f0f56c]/90"
            >
              {isSendingEmail ? (
                <>
                  <RefreshCw className="h-4 w-4 animate-spin mr-2" />
                  Enviando...
                </>
              ) : (
                <>
                  <Send className="h-4 w-4 mr-2" />
                  Enviar
                </>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </motion.div>
  );
}

export default connect(select)(DocumentManager);
