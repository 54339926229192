import axios from "axios";
import { backUrl } from "../utils/backURL";
const API_URL = backUrl;

export const fetchFlows = async (mantisBusinessId, token) => {
  try {
    const url = `${API_URL}/businessFlows/getBusinessFlows`;
    console.log("Request URL:", url);

    // Enviar evento para mostrar esqueleto
    const event = new CustomEvent('flowsLoading', { detail: true });
    window.dispatchEvent(event);

    const response = await axios.post(
      url,
      {
        mantisBusinessId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Enviar evento para ocultar esqueleto
    const endEvent = new CustomEvent('flowsLoading', { detail: false });
    window.dispatchEvent(endEvent);

    console.log("Response:", response);
    return response.data.flows;
  } catch (error) {
    // Enviar evento para ocultar esqueleto en caso de error
    const endEvent = new CustomEvent('flowsLoading', { detail: false });
    window.dispatchEvent(endEvent);

    console.error(
      "Error al obtener los flujos:",
      error.response?.data || error
    );
    throw error;
  }
};

export const createFlow = async (flowData) => {
  try {
    const url = `${API_URL}/businessFlows/createBusinessFlow`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      name: flowData.name,
      description: flowData.description,
      mantisBusinessId: flowData.mantisBusinessId,
      mantisBusinessUserId: flowData.mantisBusinessUserId,
      fieldsToExtract: flowData.fieldsToExtract,
      flowDocuments: flowData.flowDocuments,
      conditionalFields: flowData.conditionalFields,
      documentCommonName: flowData.documentCommonName,
      documentCommonFormat: flowData.documentCommonFormat,
      calculatedFields: flowData.calculatedFields,
      documentFlowName: flowData.documentFlowName,
      sources: flowData.sources,
      contactPhone: flowData.contactPhone,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al crear el flujo de negocio:",
      error.response?.data || error
    );
    throw error;
  }
};

export const deleteFlow = async (flowId, mantisBusinessId) => {
  try {
    const url = `${API_URL}/businessFlows/deleteBusinessFlow`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowId,
      mantisBusinessId: mantisBusinessId,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al eliminar el flujo de negocio:",
      error.response?.data || error
    );
    throw error;
  }
};

export const updateFlow = async (flowData) => {
  try {
    const url = `${API_URL}/businessFlows/updateBusinessFlow`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowData.id,
      name: flowData.name,
      description: flowData.description,
      mantisBusinessId: "672e82bf3ab5c40012235316", // Hardcodeado por ahora
    });
    return response.data;
  } catch (error) {
    console.error("Error al editar el flujo:", error.response?.data || error);
    throw error;
  }
};

export const saveFlowDocument = async (flowData, mantisBusinessId) => {
  try {
    const url = `${API_URL}/businessFlows/saveFlowDocument`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowData.flowId,
      document: {
        name: flowData.document.name,
        description: flowData.document.description,
        mantisBusinessId,
        exampleDocument: {
          base64: flowData.document.exampleDocument.base64,
          name: flowData.document.exampleDocument.name,
          remoteFile: flowData.document.exampleDocument.remoteFile,
          type: flowData.document.exampleDocument.type,
        },
      },
      mantisBusinessId,
      isNew: flowData.isNew,
      fieldsToExtract: flowData.fieldsToExtract.map((field) => ({
        name: field.name,
        flowId: flowData.flowId,
        mantisBusinessId,
        nameInDocument: field.nameInDocument,
        format: field.format,
        position: field.position,
        additionalDetails: field.additionalDetails,
      })),
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al guardar el documento del flujo:",
      error.response?.data || error
    );
    throw error;
  }
};

export const saveFlowConditionalFields = async (flowData) => {
  try {
    const url = `${API_URL}/businessFlows/saveFlowConditionalFields`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      flowId: flowData.flowId,
      conditionalFields: flowData.conditionalFields.map((field) => ({
        name: field.name,
        flowFieldToCompareId: field.flowFieldToCompareId,
        comparedWith: {
          type: field.comparedWith.type,
          value: field.comparedWith.value,
          fieldId: field.comparedWith.fieldId,
        },
        operator: field.operator,
        valueIfTrue: field.valueIfTrue,
        valueIfFalse: field.valueIfFalse,
      })),
      mantisBusinessId: "672e82bf3ab5c40012235316",
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al guardar los campos condicionales:",
      error.response?.data || error
    );
    throw error;
  }
};

export const deleteFlowConditionalField = async (fieldId) => {
  try {
    const url = `${API_URL}/businessFlows/deleteFlowConditionalField`;
    console.log("Request URL:", url);

    const response = await axios.post(url, {
      fieldId: fieldId,
      mantisBusinessId: "672e82bf3ab5c40012235316",
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al eliminar el campo condicional:",
      error.response?.data || error
    );
    throw error;
  }
};

export const runManualFlow = async (flowId, mantisBusinessId, files) => {
  try {
    const url = `${API_URL}/businessFlows/runManualFlow`;
    const response = await axios.post(url, {
      flowId: flowId,
      mantisBusinessId: mantisBusinessId,
      files: files,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error al ejecutar el flujo manual:",
      error.response?.data || error
    );
    throw error;
  }
};

export const sendEmailReport = async (emailData, flowId, recordIds) => {
  try {
    // Primero enviar el correo
    const url = `${API_URL}/email/sendEmail`;
    console.log("Email Data:", emailData);

    const emailResponse = await axios.post(url, {
      toEmails: emailData.toEmails,
      subject: emailData.subject,
      message: emailData.message,
      attachments: [
        {
          mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          base64: emailData.attachments[0].base64,
          filename: emailData.attachments[0].filename,
        },
      ],
    });

    // Si el correo se envió exitosamente, actualizar el estado
    if (emailResponse.data) {
      await updateSentStatus(flowId, recordIds, "ENVIADO");
    }

    return emailResponse.data;
  } catch (error) {
    console.error(
      "Error en el proceso de envío:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const updateSentStatus = async (flowId, recordIds, estadoEnvio) => {
  try {
    const url = `${API_URL}/email/updateSentStatus`;
    console.log("Updating sent status:", { flowId, recordIds, estadoEnvio });

    const response = await axios.post(url, {
      flowId,
      recordIds,
      estadoEnvio
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    return response.data;
  } catch (error) {
    console.error(
      "Error al actualizar estado de envío:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const listenToDocumentProcessing = (flowId, callbacks) => {
  try {
    const eventSource = new EventSource(
      `${API_URL}/businessFlows/notifyDocumentProcessing?flowId=${flowId}`
    );

    // Manejar mensajes recibidos
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'DOCUMENT_PROCESSING') {
        callbacks.onProcessing?.(data);
      }
    };

    // Manejar errores
    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      callbacks.onError?.(error);
      eventSource.close();
    };

    // Manejar conexión abierta
    eventSource.onopen = () => {
      callbacks.onOpen?.();
    };

    // Retornar función para cerrar la conexión
    return {
      close: () => {
        eventSource.close();
        callbacks.onClose?.();
      }
    };
  } catch (error) {
    console.error("Error al configurar SSE:", error);
    callbacks.onError?.(error);
    return {
      close: () => {}
    };
  }
};
