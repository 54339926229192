"use client";

import { useState, useEffect } from "react";
import { Button } from "./components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CardDescription,
} from "./components/ui/card";
import {
  PlusCircle,
  GitBranch,
  Edit,
  Trash2,
  ArrowLeft,
  Settings,
  Save,
  FileText,
} from "lucide-react";
import { Input } from "./components/ui/input";
import { Textarea } from "./components/ui/textarea";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./components/ui/alert-dialog";
import { Skeleton } from "./components/ui/skeleton";
import { toast } from "./components/ui/use-toast";
import * as mantisFlows from "../ApiCalls/mantisFlows";
import Chat from "./Chat";
//import ChatEditFlow from "./ChatEditFlow";

const translations = {
  en: {
    title: "Flows",
    createFlow: "Create New Flow",
    loading: "Loading...",
    backToFlows: "Back to Flows",
    flowInfo: {
      documents: "Documents",
      extractedFields: "Extracted fields",
    },
    actions: {
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      save: "Save",
      create: "Create",
    },
    deleteDialog: {
      title: "Are you sure?",
      description:
        "This action cannot be undone. This will permanently delete the flow and all associated data.",
      confirmButton: "Delete",
      cancelButton: "Cancel",
    },
    toast: {
      flowCreated: {
        title: "Flow Created",
        description: "Your new flow has been created successfully.",
      },
      flowUpdated: {
        title: "Flow Saved",
        description: "Your flow has been updated successfully.",
      },
      flowDeleted: {
        title: "Flow Deleted",
        description: "The flow has been deleted successfully.",
      },
      error: {
        title: "Error",
        loadError: "Could not load flows. Please try again.",
        saveError: "Could not save flow. Please try again.",
        deleteError: "Could not delete flow. Please try again.",
      },
    },
  },
  es: {
    title: "Flujos",
    createFlow: "Crear Nuevo Flujo",
    loading: "Cargando...",
    backToFlows: "Volver a Flujos",
    flowInfo: {
      documents: "Documentos",
      extractedFields: "Campos extraídos",
    },
    actions: {
      edit: "Editar",
      delete: "Eliminar",
      cancel: "Cancelar",
      save: "Guardar",
      create: "Crear",
    },
    deleteDialog: {
      title: "¿Estás seguro?",
      description:
        "Esta acción no se puede deshacer. Esto eliminará permanentemente el flujo y todos sus datos asociados.",
      confirmButton: "Eliminar",
      cancelButton: "Cancelar",
    },
    toast: {
      flowCreated: {
        title: "Flujo Creado",
        description: "Tu nuevo flujo ha sido creado exitosamente.",
      },
      flowUpdated: {
        title: "Flujo Guardado",
        description: "Tu flujo ha sido actualizado exitosamente.",
      },
      flowDeleted: {
        title: "Flujo Eliminado",
        description: "El flujo ha sido eliminado exitosamente.",
      },
      error: {
        title: "Error",
        loadError:
          "No se pudieron cargar los flujos. Por favor, intenta de nuevo.",
        saveError: "No se pudo guardar el flujo. Por favor, intenta de nuevo.",
        deleteError:
          "No se pudo eliminar el flujo. Por favor, intenta de nuevo.",
      },
    },
  },
  pt: {
    title: "Fluxos",
    createFlow: "Criar Novo Fluxo",
    loading: "Carregando...",
    backToFlows: "Voltar aos Fluxos",
    flowInfo: {
      documents: "Documentos",
      extractedFields: "Campos extraídos",
    },
    actions: {
      edit: "Editar",
      delete: "Excluir",
      cancel: "Cancelar",
      save: "Salvar",
      create: "Criar",
    },
    deleteDialog: {
      title: "Tem certeza?",
      description:
        "Esta ação não pode ser desfeita. Isso excluirá permanentemente o fluxo e todos os dados associados.",
      confirmButton: "Excluir",
      cancelButton: "Cancelar",
    },
    toast: {
      flowCreated: {
        title: "Fluxo Criado",
        description: "Seu novo fluxo foi criado com sucesso.",
      },
      flowUpdated: {
        title: "Fluxo Salvo",
        description: "Seu fluxo foi atualizado com sucesso.",
      },
      flowDeleted: {
        title: "Fluxo Excluído",
        description: "O fluxo foi excluído com sucesso.",
      },
      error: {
        title: "Erro",
        loadError:
          "Não foi possível carregar os fluxos. Por favor, tente novamente.",
        saveError:
          "Não foi possível salvar o fluxo. Por favor, tente novamente.",
        deleteError:
          "Não foi possível excluir o fluxo. Por favor, tente novamente.",
      },
    },
  },
};

export default function Flows({ language }) {
  const t = translations["es"];
  const [flows, setFlows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [editedFlow, setEditedFlow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isChatActive, setIsChatActive] = useState(false);

  useEffect(() => {
    loadFlows();
  }, []);

  const loadFlows = async () => {
    setIsLoading(true);
    try {
      const fetchedFlows = await mantisFlows.fetchFlows();
      console.log(fetchedFlows);
      const adaptedFlows = fetchedFlows.map((flow) => ({
        _id: flow._id,
        name: flow.name,
        description: flow.description,
        documents: flow.documents,
        extractedFields: flow.fieldsToExtract,
        conditionalFields: flow.conditionalFields,
      }));
      setFlows(adaptedFlows);
    } catch (error) {
      console.error("Failed to fetch flows:", error);
      toast({
        title: t.toast.error.title,
        description: t.toast.error.loadError,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateFlow = () => {
    const newFlow = {
      _id: `temp-${Date.now()}`,
      name: "New Flow",
      description: "",
      documents: [],
      extractedFields: [],
    };
    setSelectedFlow(newFlow);
    setEditedFlow(newFlow);
    setIsChatActive(true);
  };

  const handleSaveNewFlow = async (flowData) => {
    flowData = {
      ...flowData,
      name: editedFlow.name,
    };
    console.log(flowData);
    const res = await mantisFlows.createFlow(flowData);
    console.log(res);
    return res;
  };

  const handleEditFlow = (flow) => {
    setSelectedFlow(flow);
    setEditedFlow(flow);
    setIsEditing(true);
    setIsChatActive(false);
  };

  const handleDeleteFlow = async (_id) => {
    setIsLoading(true);
    try {
      const response = await mantisFlows.deleteFlow(
        _id,
        "672e82bf3ab5c40012235316"
      );
      if (response.success) {
        setFlows(flows.filter((flow) => flow._id !== _id));
        toast({
          title: t.toast.flowDeleted.title,
          description: t.toast.flowDeleted.description,
        });
      } else {
        throw new Error("Could not delete flow");
      }
    } catch (error) {
      console.error("Failed to delete flow:", error);
      toast({
        title: t.toast.error.title,
        description: t.toast.error.deleteError,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveFlow = async () => {
    if (editedFlow) {
      setIsLoading(true);
      try {
        let updatedFlow;
        if (editedFlow._id.startsWith("temp-")) {
          const response = await mantisFlows.createFlow({
            name: editedFlow.name,
            description: editedFlow.description,
            mantisBusinessId: "672e82bf3ab5c40012235316",
            mantisBusinessUserId: "user123",
          });
          updatedFlow = {
            ...editedFlow,
            _id: response.flow._id,
          };
          setFlows([...flows, updatedFlow]);
        } else {
          const result = await mantisFlows.updateFlow({
            id: editedFlow._id,
            name: editedFlow.name,
            description: editedFlow.description,
          });
          updatedFlow = {
            ...editedFlow,
            name: result.flow.name,
            description: result.flow.description,
          };
          setFlows(
            flows.map((flow) =>
              flow._id === updatedFlow._id ? updatedFlow : flow
            )
          );
        }
        setSelectedFlow(null);
        setEditedFlow(null);
        setIsEditing(false);
        setIsChatActive(false);
        toast({
          title: editedFlow._id.startsWith("temp-")
            ? t.toast.flowCreated.title
            : t.toast.flowUpdated.title,
          description: editedFlow._id.startsWith("temp-")
            ? t.toast.flowCreated.description
            : t.toast.flowUpdated.description,
        });
      } catch (error) {
        console.error("Failed to save flow:", error);
        toast({
          title: t.toast.error.title,
          description: t.toast.error.saveError,
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBackToFlows = () => {
    setSelectedFlow(null);
    setEditedFlow(null);
    setIsEditing(false);
    setIsChatActive(false);
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {!selectedFlow && !isEditing ? (
        <div className="container mx-auto p-4 max-w-7xl">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-xl font-semibold text-white">{t.title}</h1>
          </div>
          
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <Card className="bg-[#1a1a1a] border-[#333] hover:bg-[#2a2a2a] transition-all duration-200 h-[180px]">
              <CardContent className="p-0 h-full">
                <Button
                  onClick={handleCreateFlow}
                  variant="ghost"
                  className="w-full h-full flex flex-col items-center justify-center space-y-2 
                    hover:bg-transparent group"
                >
                  <PlusCircle className="h-12 w-12 text-[#f0f56c] opacity-80 
                    group-hover:opacity-100 transition-all" />
                  <span className="text-sm font-medium text-white 
                    group-hover:text-[#f0f56c] transition-colors">
                    {t.createFlow}
                  </span>
                </Button>
              </CardContent>
            </Card>

            {isLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                  <Card key={index} className="bg-[#1a1a1a] border-[#333] h-[180px]">
                    <CardHeader className="space-y-2 p-4">
                      <Skeleton className="h-4 w-2/3" />
                      <Skeleton className="h-3 w-full" />
                    </CardHeader>
                    <CardContent className="p-4 pt-0">
                      <Skeleton className="h-3 w-1/2 mb-1" />
                      <Skeleton className="h-3 w-1/3" />
                    </CardContent>
                  </Card>
                ))
              : flows.map((flow) => (
                  <Card
                    key={flow._id}
                    className="bg-[#1a1a1a] border-[#333] hover:bg-[#2a2a2a] 
                      hover:border-[#f0f56c] transition-all duration-200 h-[180px]
                      flex flex-col justify-between"
                  >
                    <CardHeader className="p-4 space-y-1">
                      <CardTitle className="flex items-center justify-between text-white text-sm">
                        <span className="truncate flex-1">{flow.name}</span>
                        <GitBranch className="h-4 w-4 text-[#f0f56c] flex-shrink-0 ml-2" />
                      </CardTitle>
                      <CardDescription className="text-gray-400 text-xs line-clamp-2">
                        {flow.description}
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="p-4 pt-0">
                      <div className="flex items-center gap-4 text-xs text-gray-400">
                        <span className="flex items-center gap-1">
                          <FileText className="h-3 w-3" />
                          {flow.documents.length}
                        </span>
                        <span className="flex items-center gap-1">
                          <Settings className="h-3 w-3" />
                          {flow.extractedFields.length}
                        </span>
                      </div>
                    </CardContent>
                    <CardFooter className="p-2 pt-0 flex justify-end gap-1">
                      <Button
                        variant="ghost"
                        size="xs"
                        onClick={() => handleEditFlow(flow)}
                        className="h-7 px-2 hover:bg-[#2a2a2a] text-white 
                          hover:text-[#f0f56c]"
                      >
                        <Edit className="h-3 w-3" />
                      </Button>
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button 
                            variant="ghost" 
                            size="xs"
                            className="h-7 px-2 hover:bg-red-900/20 text-gray-400
                              hover:text-red-500"
                          >
                            <Trash2 className="h-3 w-3" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent className="bg-[#1a1a1a] border-[#333]">
                          <AlertDialogHeader>
                            <AlertDialogTitle className="text-white">
                              {t.deleteDialog.title}
                            </AlertDialogTitle>
                            <AlertDialogDescription className="text-gray-400">
                              {t.deleteDialog.description}
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel className="border-[#333] 
                              hover:bg-[#2a2a2a] text-white">
                              {t.deleteDialog.cancelButton}
                            </AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() => handleDeleteFlow(flow._id)}
                              className="bg-red-900/20 hover:bg-red-900/40 text-red-500"
                            >
                              {t.deleteDialog.confirmButton}
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </CardFooter>
                  </Card>
                ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-screen bg-black">
          <div className="flex-none bg-[#1a1a1a] border-b border-[#333] p-3">
            <div className="flex items-center gap-4 max-w-7xl mx-auto">
              <Button 
                onClick={handleBackToFlows} 
                variant="ghost"
                size="sm"
                className="text-white hover:text-[#f0f56c]"
              >
                <ArrowLeft className="h-4 w-4" />
              </Button>
              <Input
                value={editedFlow?.name}
                onChange={(e) => setEditedFlow({ ...editedFlow, name: e.target.value })}
                className="text-base font-medium bg-transparent border-none text-white 
                  focus:outline-none focus:ring-0 p-0 max-w-md"
              />
              <div className="ml-auto">
                {!isChatActive && (
                  <Button 
                    onClick={() => setIsChatActive(true)}
                    size="sm"
                    className="bg-[#f0f56c] text-black hover:bg-[#f0f56c]/90"
                  >
                    Open Chat
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="flex-grow overflow-hidden">
            {isChatActive ? (
              <Chat
                onSave={handleSaveNewFlow}
                handleBackToFlows={handleBackToFlows}
                loadFlows={loadFlows}
                onClose={() => setIsChatActive(false)}
              />
            ) : isEditing ? (
              {
                /* <ChatEditFlow flow={editedFlow} language={language} /> */
              }
            ) : (
              <div className="p-6">
                <h2 className="text-2xl font-bold mb-4">Flow Details</h2>
                <p>Documents: {editedFlow?.documents?.length || 0}</p>
                <p>
                  Extracted Fields: {editedFlow?.extractedFields?.length || 0}
                </p>
                {/* Add more flow details as needed */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
