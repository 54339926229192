import {backUrl} from "../utils/backURL";
import Cookies from "js-cookie";

export const signup = async (email, password, firstName, lastName) => {
  const signupReq = await fetch(`${backUrl}/authentication/mantis/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      password,
      firstName,
      lastName
    })
  });
  const signupRes = await signupReq.json();
  return signupRes;
};

export const login = async (email, password) => {
  const loginReq = await fetch(`${backUrl}/authentication/mantis/webLogin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      password
    })
  });
  const loginRes = await loginReq.json();
  return loginRes;
};

export const businessLogin = async (email, password) => {
  const loginReq = await fetch(
    `${backUrl}/authentication/mantis/businessLogin`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    }
  );
  const loginRes = await loginReq.json();
  return loginRes;
};

export const businessSignup = async (
  name,
  contactPhone,
  contactFirstName,
  contactLastName,
  address,
  cities,
  countries,
  website,
  logo,
  identificationType,
  identificationNumber,
  contactEmail,
  password
) => {
  const signupReq = await fetch(
    `${backUrl}/authentication/mantis/businessSignup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name,
        contactPhone,
        contactFirstName,
        contactLastName,
        address,
        cities,
        countries,
        website,
        logo,
        identificationType,
        identificationNumber,
        contactEmail,
        password
      })
    }
  );
  const signupRes = await signupReq.json();
  return signupRes;
};

export const verifyToken = async (token) => {
  // return { success: false, message: "Token expired" };
  const verifyReq = await fetch(`${backUrl}/authentication/verifyToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token
    })
  });
  const verifyRes = await verifyReq.json();
  return verifyRes;
};

export const logout = async (token) => {
  Cookies.remove("csrfToken");
  const logoutReq = await fetch(`${backUrl}/authentication/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token
    })
  });
  const logoutRes = await logoutReq.json();
  return logoutRes;
};

export const businessLogout = async (token) => {
  const logoutReq = await fetch(`${backUrl}/authentication/businessLogout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token
    })
  });
  const logoutRes = await logoutReq.json();
  return logoutRes;
};

export const resetPassword = async (token, newPassword) => {
  const resetPasswordReq = await fetch(
    `${backUrl}/passwordReset/changePassword`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token,
        newPassword
      })
    }
  );
  const response = await resetPasswordReq.json();
  return response;
};

export const logoutEverywhere = async (token, userId) => {
  const logoutEverywhereReq = await fetch(
    `${backUrl}/authentication/closeAllSessions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token,
        userId
      })
    }
  );
  const response = await logoutEverywhereReq.json();
  return response;
};
