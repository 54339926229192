import * as React from "react"
import { cva } from "class-variance-authority"
import { cn } from "../../../utils/tw";

const badgeVariants = cva(
  "inline-flex items-center rounded-md px-2 py-0.5 text-xs font-medium transition-colors",
  {
    variants: {
      variant: {
        default:
          "bg-[#2a2a2a] text-gray-200 hover:bg-[#333]",
        secondary:
          "bg-[#333] text-gray-200 hover:bg-[#444]",
        destructive:
          "bg-red-900/20 text-red-500 hover:bg-red-900/30",
        outline:
          "border border-[#333] text-gray-200 hover:bg-[#2a2a2a]",
        success:
          "bg-green-900/20 text-green-500 hover:bg-green-900/30",
        warning:
          "bg-yellow-900/20 text-yellow-500 hover:bg-yellow-900/30",
        info:
          "bg-blue-900/20 text-blue-500 hover:bg-blue-900/30",
        extracted:
          "bg-[#f0f56c]/10 text-[#f0f56c] hover:bg-[#f0f56c]/20",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

function Badge({
  className,
  variant,
  ...props
}) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants } 