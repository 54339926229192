import { backUrl } from "../utils/backURL";
import { fetchWrapper } from "../utils/fetchWrapper";

export const getEmployees = async (requesterId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/getEmployees/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      activeEmployees: false,
    }),
  });

  return res;
};

export const getActiveEmployees = async (requesterId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/getEmployees/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      activeEmployees: true,
    }),
  });

  return res;
};

export const createEmployee = async (requesterId, token, employeeData) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/createEmployee/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      employeeData,
    }),
  });

  return res;
};

export const getEmployeeFolders = async (userId, mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getEmployeeFolders/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getBusinessFolders = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/archive/folders`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mantisBusinessId,
    }),
  });

  return res;
};

export const getBusinessEmails = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/getBusinessEmails/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getEmployeeFolderById = async (
  folderId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getEmployeeFolderById/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getBusinessFolderById = async (
  folderId,
  token,
  mantisBusinessId,
  page
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/folderById/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        mantisBusinessId,
        page,
      }),
    }
  );

  return res;
};

export const createEmployeeFolder = async (
  parentFolderId,
  folderData,
  mantisBusinessId,
  userId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createEmployeeFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        parentFolderId,
        folderData,
        mantisBusinessId,
        userId,
      }),
    }
  );

  return res;
};

export const createBusinessFolder = async (
  token,
  parentFolderId,
  folderData,
  mantisBusinessId,
  mantisBusinessUserId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/createFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        parentFolderId,
        folderData,
        mantisBusinessId,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const createEmployeeDocument = async (
  folderId,
  base64,
  name,
  type,
  mantisBusinessId,
  userId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createEmployeeDocument/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        base64,
        name,
        type,
        mantisBusinessId,
        userId,
      }),
    }
  );

  return res;
};

export const createBusinessDocument = async (
  folderId,
  base64,
  name,
  type,
  fileDate,
  fileSize,
  mantisBusinessId,
  mantisBusinessUserId,
  token,
  documentFormatId,
  formatValues,
  suggestedFileLocation
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/createBusinessDocument/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        base64,
        name,
        type,
        mantisBusinessId,
        mantisBusinessUserId,
        fileDate,
        fileSize,
        documentFormatId,
        formatValues,
        suggestedFileLocation,
      }),
    }
  );

  return res;
};

export const createBusinessCompressedFolder = async (
  folderId,
  base64,
  name,
  mantisBusinessId,
  mantisBusinessUserId,
  level,
  token,
  fileDate
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/createBusinessCompressedFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        base64,
        name,
        mantisBusinessId,
        mantisBusinessUserId,
        level,
        fileDate,
      }),
    }
  );

  return res;
};

export const getMantisBusinessNotificationNumber = async (
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/notifications/getMantisBusinessUnreadNotificationsAmount`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const getMantisBusinessNotifications = async (
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/notifications/getMantisBusinessNotifications`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const sendCellphoneVerification = async (cellphone) => {
  const res = await fetchWrapper(
    `${backUrl}/verify/sendBusinessCellphoneVerification`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cellphone }),
    }
  );

  return res;
};

export const verifyCellphone = async (cellphone, code) => {
  const res = await fetchWrapper(
    `${backUrl}/verify/verifyMantisBusinessCellphone`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cellphone, code }),
    }
  );

  return res;
};

export const getBusinessPermissions = async (
  requesterId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/getBusinessPermissions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        requesterId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const createMantisBusinessUser = async (
  mantisBusinessId,
  userId,
  token,
  userData
) => {
  try {
    const res = await fetchWrapper(
      `${backUrl}/mantisBusiness/userManagement/createUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          mantisBusinessUserId: userId,
          mantisBusinessId,
          userData,
        }),
      }
    );
    return res;
  } catch (error) {
    console.error("Error in updateMantisBusinessUser:", error);
    throw error;
  }
};

export const updateMantisBusinessUser = async (
  mantisBusinessUserId,
  mantisBusinessUserToUpdateId,
  userData,
  token,
  mantisBusinessId
) => {
  try {
    console.log(
      "Making API request to:",
      `${backUrl}/mantisBusiness/userManagement/editUser`
    );
    console.log("Request payload:", {
      mantisBusinessUserId,
      mantisBusinessUserToUpdateId,
      userData,
    });
    console.log("Token:", token); // Agregar este log para verificar el token

    const res = await fetchWrapper(
      `${backUrl}/mantisBusiness/userManagement/editUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Verifica que el token se envíe correctamente
        },
        body: JSON.stringify({
          mantisBusinessUserId,
          mantisBusinessUserToUpdateId,
          userData,
          mantisBusinessId,
        }),
      }
    );
    return res;
  } catch (error) {
    console.error("Error in updateMantisBusinessUser:", error);
    throw error;
  }
};

export const getMantisBusinessUsers = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/userManagement/getUsers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
      }),
    }
  );
  return res;
};

export const deleteMantisBusinessUser = async (
  mantisBusinessId,
  userId,
  mantisBusinessUserToDeleteId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/userManagement/deleteUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessUserId: userId,
        mantisBusinessId,
        mantisBusinessUserToDeleteId,
      }),
    }
  );
  return res;
};

export const sendRecoverPasswordEmail = async (email) => {
  const res = await fetchWrapper(`${backUrl}/passwordReset/business`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  return res;
};

export const resetPassword = async (token, newPassword) => {
  const resetPasswordReq = await fetchWrapper(
    `${backUrl}/passwordReset/business/changePassword`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        newPassword,
      }),
    }
  );
  const response = await resetPasswordReq.json();
  return response;
};

export const getLatestAssistantConversation = async (
  mantisBusinessUserId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/sapiens/getLatestConversation`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessUserId, mantisBusinessId }),
    }
  );

  return res;
};

export const getAssisstantConversationsHistory = async (
  mantisBusinessUserId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/sapiens/getConversationHistory`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessUserId, mantisBusinessId }),
    }
  );
  return res;
};

export const getAssistantConversationById = async (
  businessAssistantConversationId,
  mantisBusinessId,
  mantisBusinessUserId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/sapiens/getConversationById`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        businessAssistantConversationId,
        mantisBusinessId,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const postAssistantMessage = async (
  cellPhone,
  message,
  token,
  mantisBusinessUserId,
  mantisBusinessId,
  searchType
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/sapiens/postMessage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        cellPhone,
        message,
        mantisBusinessUserId,
        mantisBusinessId,
        searchType,
      }),
    }
  );

  return res;
};

export const getVisionResponse = async function (
  files,
  prompt,
  token,
  mantisBusinessId,
  mantisBusinessUserId,
  mantisMissionId,
  bulk,
  missionName,
  missionDescription
) {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/vision/getVisionResponse`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        files,
        prompt,
        mantisBusinessId,
        mantisBusinessUserId,
        mantisMissionId,
        bulk,
        missionName,
        missionDescription,
      }),
    }
  );

  return res;
};

export const getMantisMissions = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/getMantisMissionsByBusinessId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const exportMantisMissions = async (mantisMissionId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/exportMantisMissionsByBusinessId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisMissionId,
      }),
    }
  );

  return res;
};

export const getMantisMissionResults = async (mantisMissionId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/getMantisMissionResults`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisMissionId }),
    }
  );

  return res;
};

export const saveMantisMission = async (
  mantisBusinessId,
  mantisMission,
  mantisBusinessUserId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/saveMantisMission`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        mantisMission,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const updateFolder = async (
  folderId,
  folderData,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/updateFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        folderData,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const deleteFolder = async (folderId, mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/deleteFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const updateDocument = async (
  documentId,
  documentData,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/updateDocument/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        documentId,
        documentData,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const deleteDocument = async (documentId, mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/deleteDocument/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        documentId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getExtractedData = async (
  mantisBusinessId,
  token,
  fileCategory,
  currentPage,
  registersPerPage,
  tab,
  mantisBusinessUserId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/getExtractedData/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        fileCategory,
        currentPage,
        registersPerPage,
        tab,
        mantisBusinessUserId,
      }),
    }
  );
  return res;
};

export const filterOffices = async (
  token,
  filters,
  registersPerPage,
  mantisBusinessId,
  tab
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/filterOffices`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        filters,
        registersPerPage,
        tab,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const filterPqrs = async (
  token,
  filters,
  registersPerPage,
  mantisBusinessId,
  clientType,
  tab,
  mantisBusinessUserId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/filterPqrs`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        filters,
        registersPerPage,
        clientType,
        mantisBusinessId,
        type: tab,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const assignPqrstoUser = async (
  token,
  mainKey,
  mantisBusinessUserId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/pqrs/assignCaseToUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mainKey, mantisBusinessUserId, mantisBusinessId }),
    }
  );

  return res;
};

export const assignPqrstoGroup = async (
  token,
  mainKey,
  mantisBusinessGroupId,
  mantisBusinessId,
  mantisBusinessUserId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/pqrs/assignCaseToGroup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mainKey,
        mantisBusinessGroupId,
        mantisBusinessId,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const archivePQRSCase = async (
  token,
  mainKey,
  mantisBusinessId,
  archiveComment,
  mantisBusinessUserId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/pqrs/archiveCase`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mainKey,
        mantisBusinessId,
        archiveComment,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const getDocumentById = async (documentId, mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/documentById/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        documentId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getDocumentsByIds = async (
  documentIds,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/documentsByIds/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        documentIds,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getDocumentsAndEmailsByIds = async (
  ids,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/documentsAndEmailsByIds/`,
    {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const feedbackAssistantMessage = async (
  mantisBusinessId,
  businessAssistantConversationId,
  assistantResponse,
  businessQuery,
  feedbackType,
  responseIndex,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/sapiens/feedbackMessage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        businessAssistantConversationId,
        assistantResponse,
        businessQuery,
        feedbackType,
        responseIndex,
      }),
    }
  );

  return res;
};

export const getRelatedDocumentGroup = async (
  token,
  relatedDocumentsGroupId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/getRelatedDocumentsGroup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ relatedDocumentsGroupId, mantisBusinessId }),
    }
  );

  return res;
};

export const getDocumentAlerts = async (
  token,
  relatedDocumentsGroupId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/getRelatedDocumentsGroupsAlert`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ relatedDocumentsGroupId, mantisBusinessId }),
    }
  );

  return res;
};

export const updateExtractionField = async (
  token,
  field,
  value,
  mainKey,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/updateExtractionField`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ key: field, value, mainKey, mantisBusinessId }),
    }
  );

  return res;
};

export const sendDocumentsEmail = async (
  token,
  subject,
  message,
  recipientName,
  documentIds,
  mantisBusinessId,
  email
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/sendDocumentsEmail`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        subject,
        message,
        recipientName,
        documentIds,
        mantisBusinessId,
        email,
      }),
    }
  );

  return res;
};

export const sendTranslatableEmail = async (
  token,
  subject,
  message,
  recipientName,
  documentIds,
  mantisBusinessId,
  email,
  language
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/sendTranslatableEmail`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        subject,
        message,
        recipientName,
        documentIds,
        mantisBusinessId,
        email,
        language,
      }),
    }
  );
  return res;
};

export const respondPqrs = async (
  token,
  mantisBusinessId,
  mantisBusinessUserId,
  mainKey,
  email,
  subject,
  message,
  recipientName,
  documentIds,
  pqrsCase
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/pqrs/respond`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        mantisBusinessUserId,
        mainKey,
        email,
        subject,
        message,
        recipientName,
        documentIds,
        pqrsCase,
      }),
    }
  );
  return res;
};

export const downloadOfficesExcel = async (
  token,
  mantisBusinessId,
  dataToExport,
  option,
  subOption
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/downloadOfficesExcel`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dataToExport,
        option,
        subOption,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const downloadExcel = async (
  token,
  mantisBusinessId,
  type,
  dataToExport
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/downloadExcel`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId, type, dataToExport }),
    }
  );

  return res;
};

export const getGmailConnections = async (token, mantisBusinessId) => {
  const res = await fetchWrapper(
    `${backUrl}/gmailConnections/getBusinessConnections`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const deleteGmailConnection = async (
  token,
  connectionId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/gmailConnections/deleteBusinessConnection`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ connectionId, mantisBusinessId }),
    }
  );

  return res;
};

export const getExtractionFields = async (token, mantisBusinessId) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/getExtractionFields`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const getExtractionDropdownOptions = async (token, mantisBusinessId) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/getExtractionDropdownOptions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const getDocumentSignedUrl = async (
  token,
  documentId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/getDocumentSignedUrl`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ documentId, mantisBusinessId }),
    }
  );

  return res;
};

export const addDocumentToRelatedDocumentsGroup = async (
  token,
  mantisBusinessId,
  mantisBusinessUserId,
  base64,
  name,
  type,
  fileDate,
  fileSize,
  relatedDocumentsGroupId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/addDocumentToRelatedDocumentsGroup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        mantisBusinessUserId,
        base64,
        name,
        type,
        fileDate,
        fileSize,
        relatedDocumentsGroupId,
      }),
    }
  );

  return res;
};

export const getMantisBusinessGroups = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/userManagement/getGroups`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
      }),
    }
  );
  return res;
};
export const createMantisBusinessGroup = async (
  mantisBusinessId,
  token,
  groupData,
  supervisorId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/userManagement/createGroup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        groupData,
        supervisorId,
      }),
    }
  );
  return res;
};
export const editMantisBusinessGroup = async (
  mantisBusinessId,
  groupId,
  groupData,
  supervisorId,
  assignedUserIds,
  token
) => {
  try {
    const res = await fetchWrapper(
      `${backUrl}/mantisBusiness/userManagement/editGroup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          mantisBusinessId,
          groupId,
          groupData,
          supervisorId,
          assignedUserIds,
        }),
      }
    );
    return res;
  } catch (error) {
    console.error("Error en editMantisBusinessGroup:", error);
    throw error;
  }
};

export const deleteMantisBusinessGroup = async (
  mantisBusinessId,
  groupId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/userManagement/deleteGroup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        groupId,
      }),
    }
  );
  return res;
};

export const getSupervisors = async (mantisBusinessId, token) => {
  try {
    const res = await fetchWrapper(
      `${backUrl}/mantisBusiness/userManagement/getSupervisors`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ mantisBusinessId }),
      }
    );

    return res;
  } catch (error) {
    console.error("Error en la solicitud de supervisores:", error);
    return {
      success: false,
      message: error.message,
    };
  }
};

export const getInternalMessages = async (
  mantisBusinessId,
  token,
  mantisBusinessUserId
) => {
  const res = await fetchWrapper(`${backUrl}/internalMessages/getInbox`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ mantisBusinessId, mantisBusinessUserId }),
  });

  return res;
};

export const updatePqrsPriority = async (
  token,
  mainKey,
  priority,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/pqrs/updatePriority`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        mainKey,
        priority,
      }),
    }
  );
  return res;
};

export const getDocumentFormats = async (token, mantisBusinessId) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/getDocumentFormats`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const updatePqrsResponseDate = async (
  token,
  mainKey,
  responseDate,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/taktik/pqrs/updateResponseDate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId, mainKey, responseDate }),
    }
  );

  return res;
};

export const updatePqrsGroup = async (
  token,
  mainKey,
  groupId,
  groupName,
  mantisBusinessId
) => {
  console.log("esta es la data", {
    mantisBusinessId,
    mainKey,
    groupId,
    groupName,
  });
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/taktik/pqrs/updateGroup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ mantisBusinessId, mainKey, groupId, groupName }),
  });
  return res;
};

export const getFormatFieldValues = async (
  token,
  mantisBusinessId,
  formatId,
  base64,
  filename,
  fileSize,
  fileType
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/archive/getFormatFieldValues`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        formatId,
        base64,
        filename,
        fileSize,
        fileType,
      }),
    }
  );

  return res;
};

export const cloneRadicado = async (
  token,
  businessId,
  userId,
  originalMainKey,
  responseData
) => {
  try {
    const response = await fetchWrapper(
      `${backUrl}/mantisBusiness/taktik/pqrs/cloneCase`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          mainKey: originalMainKey,
          responseData: {
            ...responseData,
            originalCase: {
              ...responseData.originalCase,
              mantis_business_id: businessId,
            },
          },
          mantisBusinessId: businessId,
          mantisBusinessUserId: userId,
        }),
      }
    );
    return {
      success: response.status === 200,
      ...response,
    };
  } catch (error) {
    console.error("Error cloning radicado:", error);
    return {
      success: false,
      error: error.message || "No se pudo crear el radicado",
    };
  }
};

export const getGroupUsers = async (token, mantisBusinessId, groupId) => {
  try {
    const response = await fetchWrapper(
      `${backUrl}/mantisBusiness/userManagement/getGroups`,
      {
        method: "POST",
        headers: {

          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          groupId,
          mantisBusinessId,
        }),
      }
    );
    return {
      success: response.status === 200,
      ...response,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message || "No se pudieron obtener los grupos",
    };
  }
};

export const searchInArchive = async (token, mantisBusinessId, searchTerm) => {
  try {
    const response = await fetchWrapper(
      `${backUrl}/mantisBusiness/archive/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          mantisBusinessId,
          searchTerm,
        }),
      }
    );
    return {
      success: response.status === 200,
      ...response,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message || "No se pudieron obtener los grupos",
    };
  }
};
